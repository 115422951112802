import React, { useState } from "react";
import { Button, Dropdown, Icon, Image, Menu } from "semantic-ui-react";
import RegisterForm from "../account/Register";
import LoginForm from "../account/Login";
import { getAuth, deleteUser } from "firebase/auth";
import { LogErrors, notifyError, notifySuccess } from "../../resources/lib";
import { addDataToCollection } from "../../resources/firebaseLib";
import { queuesCollection } from "../../resources/const/firebaseCollections";
import ConfimationModal from "../modals/ConfimationModal";
import CustomModal from "../modals/CustomModal";

const LeftMenu = ({ user, logout }) => {
  const [registerModalOpen, setRegisterModalOpen] = useState(false);
  const [loginModalOpen, setLoginModalOpen] = useState(false);

  const handleOpenLoginModal = () => setLoginModalOpen(true);
  const handleCloseLoginModal = () => setLoginModalOpen(false);
  const handleOpenRegisterModal = () => setRegisterModalOpen(true);
  const handleCloseRegisterModal = () => setRegisterModalOpen(false);

  function logoutSession() {
    handleCloseLoginModal();
    handleCloseRegisterModal();
    logout();
  }

  const deleteAccount = async (value) => {
    if (value) {
      const auth = getAuth();
      const firebaseUser = auth.currentUser;

      const result = await populateCollection(user);
      if (result) {
        deleteUser(firebaseUser)
          .then(() => {
            notifySuccess("Conta apagada com sucesso.");
            logoutSession();
          })
          .catch((error) => {
            LogErrors(error);
            notifyError("Erro ao apagar a conta.");
          });
      } else {
        notifyError("Erro ao adicionar a conta à lista de contas a apagar.");
      }
    }
  };

  async function populateCollection(data) {
    try {
      const addData = {
        docId: data.uid,
        collection: "userAccounts",
      };

      return await addDataToCollection(queuesCollection, addData);
    } catch (error) {
      LogErrors(error);
      return false;
    }
  }

  return (
    <Menu.Menu position="right">
      {user ? (
        <Menu.Item style={{ padding: "0px" }}>
          {user.photo === "icon" ? (
            <Icon name="user circle" size="large" />
          ) : (
            <Image avatar src={user.photo} style={{ marginLeft: "10px" }} />
          )}
          <Dropdown
            item
            text={user.name}
            style={{
              maxWidth: "90%",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            <Dropdown.Menu>
              <Dropdown.Item onClick={logoutSession}>
                <Icon name="sign-out" /> Sair
              </Dropdown.Item>
              <ConfimationModal
                title="Tem certeza de que deseja apagar a sua conta?"
                trigger={
                  <Dropdown.Item style={{ color: "black", cursor: "pointer" }}>
                    <Icon name="trash alternate" /> Apagar Conta
                  </Dropdown.Item>
                }
                action={deleteAccount}
              />
            </Dropdown.Menu>
          </Dropdown>
        </Menu.Item>
      ) : (
        <>
          {/* Menu Item com Grupo de Botões */}
          <Menu.Item>
            <Button.Group>
              {/* Botão de Login */}
              <Button
                basic
                onClick={handleOpenLoginModal}
                style={{
                  padding: "10px 20px",
                  borderRadius: "30px 0 0 30px", // Bordas arredondadas à esquerda
                  backgroundColor: "white",
                  color: "black",
                  border: "2px solid black",
                }}
              >
                <Icon name="sign-in" /> Login
              </Button>

              {/* Botão de Registar */}
              <Button
                onClick={handleOpenRegisterModal}
                style={{
                  padding: "10px 20px",
                  borderRadius: "0 30px 30px 0", // Bordas arredondadas à direita
                  backgroundColor: "black",
                  color: "white",
                  border: "2px solid black",
                }}
              >
                <Icon name="signup" /> Registar
              </Button>
            </Button.Group>
          </Menu.Item>

          {/* Modal de Login */}
          <CustomModal
            isOpen={loginModalOpen}
            handleClose={handleCloseLoginModal}
            title="Login"
            formComponent={<LoginForm />}
            iconName="sign-in"
          />

          {/* Modal de Registar */}
          <CustomModal
            isOpen={registerModalOpen}
            handleClose={handleCloseRegisterModal}
            title="Registar"
            formComponent={<RegisterForm />}
            iconName="signup"
          />
        </>
      )}
    </Menu.Menu>
  );
};

export default LeftMenu;
