import React from "react";
import { Modal, Icon } from "semantic-ui-react";

const CustomModal = ({
  isOpen,
  handleClose,
  title,
  formComponent,
  iconName,
}) => {
  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      size="tiny"
      dimmer={{
        inverted: true, // Fundo escuro personalizado
      }}
      style={{
        borderRadius: "20px", // Bordas arredondadas
        boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.25)", // Sombra suave
        animation: "fadeIn 0.3s ease-in-out", // Animação de entrada
      }}
    >
      {/* Cabeçalho */}
      <Modal.Header
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "15px 20px",
          backgroundColor: "#5dbcd2", // Fundo da cor primária
          color: "white", // Texto branco
          borderTopLeftRadius: "20px",
          borderTopRightRadius: "20px",
        }}
      >
        <span style={{ fontSize: "1.5em", fontWeight: "bold" }}>
          {iconName && <Icon name={iconName} />} {title}
        </span>
        {/* Botão de Fechar */}
        <Icon
          name="close"
          size="large"
          style={{ cursor: "pointer", color: "white" }}
          onClick={handleClose}
        />
      </Modal.Header>

      {/* Conteúdo */}
      <Modal.Content style={{ padding: "25px", fontSize: "1.2em" }}>
        {formComponent}
      </Modal.Content>
    </Modal>
  );
};

export default CustomModal;
