import React, { useState } from "react";
import {
  Container,
  Form,
  Input,
  Button,
  Icon,
  Segment,
} from "semantic-ui-react";
import { auth } from "../../firebase";
import { createUserWithEmailAndPassword } from "firebase/auth";
import {
  LogErrors,
  getCurrentDateTime,
  notifyError,
  notifySuccess,
} from "../../resources/lib";
import { useAuth } from "./AuthContext";
import ReCAPTCHA from "react-google-recaptcha";
import { useNavigate } from "react-router-dom";
import { usersCollection } from "../../resources/const/firebaseCollections";
import { addDataToCollection } from "../../resources/firebaseLib";

const RegisterForm = () => {
  const [isVerified, setIsVerified] = useState(
    process.env.REACT_APP_ENV === "dev"
  );
  const { login } = useAuth();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e, { name, value }) =>
    setFormData({ ...formData, [name]: value });

  const toggleShowPassword = () => setShowPassword(!showPassword);

  const validObject = () => {
    return (
      formData.name.trim() !== "" &&
      formData.email.trim() !== "" &&
      formData.password.trim() !== "" &&
      isVerified
    );
  };

  async function registerAccount(data) {
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        data.email,
        data.password
      );
      return userCredential.user.uid;
    } catch (error) {
      LogErrors(error);
      notifyError(error);
      return null;
    }
  }

  async function populateCollection(data, uid) {
    try {
      const addData = {
        uid: uid,
        name: data.name.trim(),
        email: data.email.trim(),
        creationDate: getCurrentDateTime(),
        isDelete: 0,
      };

      return await addDataToCollection(usersCollection, addData);
    } catch (error) {
      LogErrors(error);
      return false;
    }
  }

  async function postData(data) {
    let result = await registerAccount(data);
    if (result !== null) {
      let uid = result;
      result = await populateCollection(data, uid);
      if (result !== null) {
        login({
          uid: uid,
          name: formData.name,
          email: formData.email,
          photo: "icon",
        });
        notifySuccess("Conta criada com sucesso.");
        navigate("/");
      } else {
        notifyError("Falha a criar conta para este utilizador.");
      }
    } else {
      notifyError("Falha a criar autenticação para este utilizador.");
    }
  }

  const handleSubmit = () => {
    if (isVerified) postData(formData);
    else notifyError("Por favor, confirme que não é um robô.");
  };

  return (
    <Container style={{ marginTop: "2em", marginBottom: "2em" }}>
      <Segment basic>
        <Form
          onSubmit={handleSubmit}
          style={{
            padding: "20px",
            maxWidth: "400px",
            margin: "auto",
          }}
        >
          {/* Nome */}
          <Form.Field style={{ marginBottom: "20px" }}>
            <label
              style={{
                fontWeight: "bold",
                fontSize: "1.1em",
                marginBottom: "5px",
              }}
            >
              Nome
            </label>
            <Input
              placeholder="Nome completo"
              name="name"
              className="primary-color"
              value={formData.name}
              onChange={handleChange}
              style={{
                borderRadius: "10px",
                border: "1px solid #ddd",
                padding: "10px",
              }}
            />
          </Form.Field>

          {/* Email */}
          <Form.Field style={{ marginBottom: "20px" }}>
            <label
              style={{
                fontWeight: "bold",
                fontSize: "1.1em",
                marginBottom: "5px",
              }}
            >
              Email
            </label>
            <Input
              placeholder="Endereço de email"
              name="email"
              type="email"
              className="primary-color"
              value={formData.email}
              onChange={handleChange}
              autoComplete="username"
              style={{
                borderRadius: "10px",
                border: "1px solid #ddd",
                padding: "10px",
              }}
            />
          </Form.Field>

          {/* Senha */}
          <Form.Field style={{ marginBottom: "20px" }}>
            <label
              style={{
                fontWeight: "bold",
                fontSize: "1.1em",
                marginBottom: "5px",
              }}
            >
              Senha
            </label>
            <Input
              icon={
                <Icon
                  name={showPassword ? "eye slash outline" : "eye"}
                  link
                  onClick={toggleShowPassword}
                />
              }
              placeholder="Crie uma senha"
              name="password"
              className="primary-color-input"
              type={showPassword ? "text" : "password"}
              value={formData.password}
              onChange={handleChange}
              autoComplete="current-password"
              style={{
                borderRadius: "10px",
                border: "1px solid #ddd",
                padding: "10px",
              }}
            />
          </Form.Field>

          {/* ReCAPTCHA */}
          {process.env.REACT_APP_ENV !== "dev" && (
            <Form.Field style={{ textAlign: "center", marginBottom: "20px" }}>
              <ReCAPTCHA
                size="normal"
                sitekey="6Lc3bqopAAAAAK7LTmTMUUIt2DxubgzS2i5ZOV9l"
                onChange={() => setIsVerified(true)}
                onExpired={() => setIsVerified(false)}
              />
            </Form.Field>
          )}

          {/* Botão Registar */}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <Button
              type="submit"
              color="green"
              className="register-form-button"
              style={{
                padding: "10px 30px",
                borderRadius: "30px",
                fontSize: "1.2em",
                width: "70%",
              }}
              disabled={!validObject()}
            >
              Registar
            </Button>
          </div>
        </Form>
      </Segment>
    </Container>
  );
};

export default RegisterForm;
