import React, { useState } from "react";
import { Form, Button, Select } from "semantic-ui-react";
import {
  LogErrors,
  getCurrentDateTime,
  notifyError,
  notifySuccess,
  removeId,
} from "../../../resources/lib";
import { medicationsCollection } from "../../../resources/const/firebaseCollections";
import {
  addDataToCollection,
  updateDataByDocId,
} from "../../../resources/firebaseLib";

const MedicationForm = ({
  pet,
  reload,
  veterinarians,
  medication,
  closeModal,
}) => {
  const [formData, setFormData] = useState(
    medication
      ? medication
      : {
          reason: "",
          medicationName: "",
          medicationType: "",
          startDate: "",
          duration: "",
          dosage: "",
          doctorName: "",
        }
  );
  const isUpdate = medication ? true : false;

  const medicationTypeOptions = [
    { key: "pill", text: "Comprimido", value: "Comprimido" },
    { key: "liquid", text: "Líquido", value: "Líquido" },
    { key: "cream", text: "Creme", value: "Creme" },
    { key: "drops", text: "Gotas", value: "Gotas" },
    { key: "gel", text: "Gel", value: "Gel" },
  ];

  const formatVeterinarians = (veterinarians) => {
    let vetOptions = [];
    let counter = 0;
    veterinarians.forEach((vet) => {
      vetOptions.push({
        key: counter,
        text: vet.name,
        value: vet.id,
      });
      counter++;
    });
    return vetOptions;
  };

  const handleChange = (e, { name, value }) =>
    setFormData({ ...formData, [name]: value });

  async function populateCollection(data, uid) {
    try {
      const addData = {
        uid: uid,
        reason: data.reason.trim(),
        medicationName: data.medicationName.trim(),
        medicationType: data.medicationType,
        startDate: data.startDate,
        duration: data.duration,
        dosage: data.dosage,
        doctorName: data.doctorName,
        petType: pet.type,
        creationDate: getCurrentDateTime(),
      };

      return await addDataToCollection(medicationsCollection, addData);
    } catch (error) {
      LogErrors(error);
      return false;
    }
  }

  const validObject = () => {
    return (
      formData.reason !== "" &&
      formData.medicationName.trim() !== "" &&
      formData.medicationType !== "" &&
      formData.startDate !== "" &&
      formData.duration !== "" &&
      formData.dosage !== "" &&
      formData.doctorName !== ""
    );
  };

  const handleSubmit = async () => {
    if (!isUpdate) {
      const result = await populateCollection(formData, pet.id);
      if (result) {
        notifySuccess("Medicação adicionada com sucesso.");
        reload(pet);
      } else {
        notifyError("Falha ao criar a sua Medicação.");
      }
    } else {
      const result = await updateDataByDocId(
        medicationsCollection,
        formData.id,
        removeId(formData)
      );
      if (result) {
        notifySuccess("Medicação atualizado com sucesso.");
        reload(pet);
        closeModal();
      } else {
        notifyError("Falha ao atualizar o seu Medicação.");
        closeModal();
      }
    }
    closeModal(true);
  };

  return (
    <Form
      onSubmit={handleSubmit}
      style={{
        padding: "20px",
      }}
    >
      {/* Nome do Medicamento e Razão */}
      <Form.Group widths="equal">
        <Form.Input
          label="Nome do Medicamento"
          placeholder="Nome"
          name="medicationName"
          value={formData.medicationName}
          onChange={handleChange}
          required
          style={{ borderRadius: "10px" }}
        />
        <Form.Input
          label="Razão da Medicação"
          placeholder="Razão"
          name="reason"
          value={formData.reason}
          onChange={handleChange}
          required
          style={{ borderRadius: "10px" }}
        />
      </Form.Group>

      {/* Tipo de Medicamento */}
      <Form.Field>
        <Form.Field
          control={Select}
          label="Tipo de Medicamento"
          options={medicationTypeOptions}
          placeholder="Selecione o tipo"
          name="medicationType"
          value={formData.medicationType}
          onChange={handleChange}
          required
          style={{ borderRadius: "10px" }}
        />
      </Form.Field>

      {/* Datas e Duração */}
      <Form.Group widths="equal">
        <Form.Input
          label="Data de Início"
          type="date"
          name="startDate"
          value={formData.startDate}
          onChange={handleChange}
          required
          style={{ borderRadius: "10px" }}
        />
        <Form.Input
          label="Duração do Tratamento"
          placeholder="3 meses, 2 semanas, ..."
          name="duration"
          value={formData.duration}
          onChange={handleChange}
          required
          style={{ borderRadius: "10px" }}
        />
      </Form.Group>

      {/* Dosagem */}
      <Form.Field>
        <Form.Input
          label="Dosagem"
          placeholder="1 comprimido, 3 gotas, ..."
          name="dosage"
          value={formData.dosage}
          onChange={handleChange}
          required
          style={{ borderRadius: "10px" }}
        />
      </Form.Field>

      {/* Veterinário */}
      {veterinarians.length > 0 ? (
        <Form.Input
          control={Select}
          label="Nome do Médico"
          options={formatVeterinarians(veterinarians)}
          placeholder="Selecione o médico"
          name="doctorName"
          value={formData.doctorName}
          onChange={handleChange}
          required
          style={{ borderRadius: "10px" }}
        />
      ) : (
        <Form.Field>
          <label style={{ color: "red", fontWeight: "bold" }}>
            Adicione um Veterinário primeiro.
          </label>
        </Form.Field>
      )}

      {/* Botão Gravar */}
      <div style={{ textAlign: "center", marginTop: "20px" }}>
        <Button
          type="submit"
          className="primary-color"
          disabled={!validObject()}
          style={{
            padding: "10px 30px",
            borderRadius: "30px",
            fontSize: "1.2em",
          }}
        >
          Gravar
        </Button>
      </div>
    </Form>
  );
};

export default MedicationForm;
