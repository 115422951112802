import React, { useEffect, useState } from "react";
import NavbarDesktop from "./NavBarDesktop";
import NavbarMobile from "./NavBarMobile";

const Navbar = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 769);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 769);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {/* Menu para dispositivos móveis */}
      {isMobile ? (
        <NavbarMobile />
      ) : (
        // Menu padrão para desktop
        <NavbarDesktop />
      )}
    </>
  );
};

export default Navbar;
