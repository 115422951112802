import React from "react";
import { Accordion, Segment } from "semantic-ui-react";

const BirdCareDetails = () => {
  const panels = [
    {
      key: "veterinary-exam",
      title: "Exame Veterinário Inicial",
      content: {
        content: (
          <div>
            <p>
              Ao adquirir um novo pássaro, leve-o a um veterinário especializado
              em aves para um check-up inicial. Este exame pode incluir
              avaliação física, testes para doenças infeciosas comuns em
              pássaros e orientações sobre cuidados específicos.
            </p>
          </div>
        ),
      },
    },
    {
      key: "regular-checkups",
      title: "Check-ups Regulares",
      content: {
        content: (
          <div>
            <p>
              Anualmente: Realize exames anuais para avaliar a saúde geral, a
              nutrição e prevenir possíveis problemas de saúde.
            </p>
          </div>
        ),
      },
    },
    {
      key: "nutrition",
      title: "Nutrição Adequada",
      content: {
        content: (
          <div>
            <p>
              Dieta equilibrada: Deve incluir rações específicas para a espécie,
              complementadas com frutas e vegetais frescos adequados para aves.
              Evite alimentos tóxicos como abacate, chocolate e cafeína.
            </p>
          </div>
        ),
      },
    },
    {
      key: "environment-maintenance",
      title: "Manutenção do Ambiente",
      content: {
        content: (
          <div>
            <p>
              Limpeza diária: Limpe a gaiola e os acessórios diariamente para
              evitar o acumular de fezes e restos de comida, que podem levar ao
              crescimento de bactérias e fungos.
            </p>
            <p>
              Tamanho e estímulos na gaiola: Garanta que a gaiola seja
              suficientemente grande para permitir que o pássaro voe e se
              exercite. Adicione brinquedos e poleiros para estimular a
              atividade física e mental.
            </p>
          </div>
        ),
      },
    },
    {
      key: "parasite-prevention",
      title: "Prevenção de Parasitas",
      content: {
        content: (
          <div>
            <p>
              Avaliação periódica: Consulte um veterinário sobre a necessidade
              de tratamentos contra parasitas, como ácaros e piolhos,
              especialmente se o pássaro tem acesso ao exterior ou foi
              recentemente adquirido.
            </p>
          </div>
        ),
      },
    },
  ];

  return (
    <Segment basic>
      <Accordion panels={panels} styled fluid />
    </Segment>
  );
};

export default BirdCareDetails;
