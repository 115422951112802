import React, { useState } from "react";
import { Form, Button, Input } from "semantic-ui-react";
import {
  addDataToCollection,
  updateDataByDocId,
} from "../../../resources/firebaseLib";
import {
  LogErrors,
  getCurrentDateTime,
  notifyError,
  notifySuccess,
  removeId,
} from "../../../resources/lib";
import { chipsCollection } from "../../../resources/const/firebaseCollections";

const PetChipForm = ({ pet, chip, reload }) => {
  const [chipInfo, setChipInfo] = useState(
    chip.length > 0
      ? chip[0]
      : {
          chipNumber: "",
          date: "",
          place: "",
        }
  );
  const isUpdate = chip.length > 0 ? true : false;

  const handleChange = (e, { name, value }) => {
    setChipInfo({ ...chipInfo, [name]: value });
  };

  async function populateCollection(data, uid) {
    try {
      const addData = {
        uid: uid,
        chipNumber: data.chipNumber.trim(),
        date: data.date,
        place: data.place.trim(),
        petType: pet.type,
        creationDate: getCurrentDateTime(),
        isDelete: 0,
      };

      return await addDataToCollection(chipsCollection, addData);
    } catch (error) {
      LogErrors(error);
      return false;
    }
  }

  const validObject = () => {
    return chipInfo.chipNumber.trim() !== "" && chipInfo.date !== "";
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isUpdate) {
      const result = await populateCollection(chipInfo, pet.id);
      if (result) {
        notifySuccess("Chip adicionado com sucesso.");
        reload(pet);
      } else {
        notifyError("Falha ao criar o seu chip.");
      }
    } else {
      const result = await updateDataByDocId(
        chipsCollection,
        chipInfo.id,
        removeId(chipInfo)
      );
      if (result) {
        notifySuccess("Chip atualizado com sucesso.");
        reload(pet);
      } else {
        notifyError("Falha ao atualizar o seu chip.");
      }
    }
  };

  return (
    <Form
      onSubmit={handleSubmit}
      style={{
        padding: "20px",
      }}
    >
      {/* Número do Chip e Data de Implantação */}
      <Form.Group widths="equal">
        <Form.Field required>
          <label>Número do Chip</label>
          <Input
            placeholder="Número do chip"
            name="chipNumber"
            value={chipInfo.chipNumber}
            onChange={handleChange}
            style={{ borderRadius: "10px" }}
          />
        </Form.Field>
        <Form.Field required>
          <label>Data de Implantação</label>
          <Input
            type="date"
            name="date"
            value={chipInfo.date}
            onChange={handleChange}
            style={{ borderRadius: "10px" }}
          />
        </Form.Field>
      </Form.Group>

      {/* Localização do Chip no Corpo */}
      <Form.Field>
        <label>Localização do Chip no Corpo</label>
        <Input
          placeholder="Localização do Chip"
          name="place"
          value={chipInfo.place}
          onChange={handleChange}
          style={{ borderRadius: "10px" }}
        />
      </Form.Field>

      {/* Botão Gravar Chip */}
      <div style={{ textAlign: "center", marginTop: "20px" }}>
        <Button
          type="submit"
          className="primary-color"
          disabled={!validObject()}
          style={{
            padding: "10px 30px",
            borderRadius: "30px",
            fontSize: "1.2em",
          }}
        >
          Gravar Chip
        </Button>
      </div>
    </Form>
  );
};

export default PetChipForm;
