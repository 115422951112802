import React from "react";
import HomeBanner from "./components/HomeBanner";
import FeaturesSection from "./components/FeaturesSection";
import { useAuth } from "../../account/AuthContext";
import HomePets from "./HomePets";
import AppearOnScroll from "../../global/AppearOnScroll";
import SeasonalCareAlert from "../basicCare/components/SeasonalCareAlert";

const Home = () => {
  const { user } = useAuth();

  if (user) return <HomePets />;

  return (
    <>
      <HomeBanner />
      <br />
      <FeaturesSection />
      <AppearOnScroll>
        <SeasonalCareAlert />
      </AppearOnScroll>
    </>
  );
};

export default Home;
