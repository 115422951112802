import React from "react";
import { Accordion, Segment } from "semantic-ui-react";

const CatCareDetails = () => {
  const panels = [
    {
      key: "veterinary-exam",
      title: "Exame Veterinário Inicial",
      content: {
        content: (
          <div>
            <p>
              Ao adotar um novo gato, leve-o ao veterinário para um check-up
              inicial. Este exame pode incluir avaliação física, testes para
              doenças infeciosas e orientações sobre os cuidados necessários.
            </p>
          </div>
        ),
      },
    },
    {
      key: "vaccinations-kittens",
      title: "Vacinações Iniciais (Gatinhos)",
      content: {
        content: (
          <div>
            <p>
              6 a 8 semanas: Primeira dose da vacina tríplice felina
              (calicivírus, herpesvírus e panleucopenia).
            </p>
            <p>10 a 12 semanas: Segunda dose da tríplice felina.</p>
            <p>
              14 a 16 semanas: Terceira dose da tríplice felina e primeira dose
              da vacina antirrábica.
            </p>
          </div>
        ),
      },
    },
    {
      key: "vaccinations-adults",
      title: "Vacinações Anuais (Gatos Adultos)",
      content: {
        content: (
          <div>
            <p>Anualmente: Reforço da vacina tríplice felina.</p>
            <p>
              Anualmente: Reforço da vacina antirrábica (conforme a legislação
              portuguesa).
            </p>
          </div>
        ),
      },
    },
    {
      key: "parasite-prevention",
      title: "Prevenção de Parasitas",
      content: {
        content: (
          <div>
            <p>Mensalmente: Tratamento preventivo contra pulgas e carraças.</p>
            <p>
              Mensal ou Trimestralmente: Tratamento preventivo contra vermes
              intestinais e, se necessário, contra dirofilariose.
            </p>
          </div>
        ),
      },
    },
    {
      key: "chip-and-sterilization",
      title: "Colocação de Chip e Esterilização",
      content: {
        content: (
          <div>
            <p>
              Colocação de Chip: Realizada na primeira consulta veterinária ou
              ao ser vacinado.
            </p>
            <p>
              Esterilização (Castrar ou Ovariohisterectomia): Entre os 5 e 6
              meses, recomendada para prevenir gestações indesejadas e reduzir
              comportamentos hormonais.
            </p>
          </div>
        ),
      },
    },
  ];

  return (
    <Segment basic>
      <Accordion panels={panels} styled fluid />
    </Segment>
  );
};

export default CatCareDetails;
