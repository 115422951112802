import React from "react";
import { Grid, Card, Icon } from "semantic-ui-react";
import { motion } from "framer-motion";

const FeaturesSection = () => {
  return (
    <Grid
      textAlign="center"
      stackable
      style={{
        margin: "auto",
        padding: "20px 0",
      }}
    >
      <Grid.Row columns={3}>
        {[
          {
            name: "paw",
            header: "Perfil Completo do Animal",
            description:
              "Registe todas as informações do seu animal de estimação num único lugar: histórico médico, vacinas, peso, desparasitação e muito mais. Organize a saúde do seu pet de forma simples.",
          },
          {
            name: "heartbeat",
            header: "Saúde e Bem-Estar do Animal",
            description:
              "Acompanhe a saúde do seu animal de estimação com lembretes automáticos para vacinas e medicação. Garanta o bem-estar do seu pet com dicas úteis e informações importantes.",
          },
          {
            name: "find",
            header: "Encontre Animais Perdidos",
            description:
              "Ajude a localizar animais de estimação desaparecidos e conecte-os aos seus donos através da nossa rede colaborativa. Faça parte da comunidade que reúne famílias e animais.",
          },
        ].map((card, index) => (
          <Grid.Column
            key={index}
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "10px",
            }}
          >
            <motion.div
              initial={{ y: 100, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.5, delay: index * 0.2 }}
              whileHover={{ scale: 1.05 }}
              style={{
                borderRadius: "15px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                backgroundColor: "#ffffff",
                padding: "20px",
                width: "100%",
                maxWidth: "350px",
                textAlign: "center",
                cursor: "pointer",
              }}
            >
              <Icon
                name={card.name}
                size="huge"
                style={{
                  color: "#5dbcd2",
                  marginBottom: "20px",
                }}
              />
              <Card.Header
                style={{
                  fontSize: "1.5em",
                  fontWeight: "bold",
                  color: "#333",
                  marginBottom: "10px",
                }}
              >
                {card.header}
              </Card.Header>
              <Card.Description
                style={{
                  fontSize: "1em",
                  color: "#555",
                  lineHeight: "1.5",
                }}
              >
                {card.description}
              </Card.Description>
            </motion.div>
          </Grid.Column>
        ))}
      </Grid.Row>
    </Grid>
  );
};

export default FeaturesSection;
