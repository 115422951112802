import React from "react";
import { Header, Segment, Container } from "semantic-ui-react";
import { useAuth } from "../../../account/AuthContext";
import logo from "../../../../resources/new_logo_nbg.png";

const HomePetBanner = () => {
  const { user } = useAuth();
  const isMobile = window.innerWidth < 758;

  return (
    <Segment
      inverted
      textAlign="center"
      style={{ minHeight: "25vh", padding: "1em 0em" }}
      vertical
    >
      <Container
        style={{
          backgroundImage: `url(${logo})`,
          backgroundSize: "cover",
          backgroundPosition: "center center",
          textAlign: "center",
          padding: isMobile ? "10px 2px" : "40px 20px",
        }}
      >
        <Header
          as="h1"
          content={`Bem-vindo(a), ${user?.name || "Usuário"}!`}
          inverted
          style={{
            fontSize: "4em",
            fontWeight: "normal",
            marginBottom: 0,
            marginTop: "3em",
          }}
        />
        <Header
          as="h2"
          content="Aqui está a sua lista de animais de estimação:"
          inverted
          style={{ fontSize: "1.7em", fontWeight: "normal" }}
        />
      </Container>
    </Segment>
  );
};

export default HomePetBanner;
