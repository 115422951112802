import React, { useEffect, useState } from "react";
import { Button, Header, Icon, List, Segment } from "semantic-ui-react";
import { Link } from "react-router-dom";
import CreationModals from "./modals/CreationModals";
import MedicationForm from "./forms/MedicationForm";
import {
  findNameById,
  findObjById,
  notifyError,
  notifySuccess,
} from "../../resources/lib";
import { deleteDocById } from "../../resources/firebaseLib";
import { medicationsCollection } from "../../resources/const/firebaseCollections";
import ConfimationModal from "../modals/ConfimationModal";
import VeterinarianInfoModal from "./views/VeterinarianInfoModal";

const MedicationList = ({
  pet,
  medications,
  veterinarians,
  isView,
  reload,
}) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleModal = async (value, item) => {
    if (value) {
      const result = deleteDocById(medicationsCollection, item.id);
      if (result) {
        notifySuccess(`Medicação apagada com sucesso.`);
      } else {
        notifyError(`Falha ao apagar Medicação.`);
      }
      reload(pet);
    }
  };

  return (
    <>
      <Header as="h5">Lista de Medicações</Header>
      {medications.length === 0 ? (
        <Segment>Nenhuma medicação adicionada.</Segment>
      ) : (
        <List divided relaxed style={{ maxHeight: "300px", overflowY: "auto" }}>
          {medications.map((medication, index) => (
            <List.Item key={index}>
              {windowWidth > 758 && (
                <List.Icon name="pills" size="large" verticalAlign="middle" />
              )}
              <List.Content>
                {!isView ? (
                  <CreationModals
                    title="Atualizar Medicação"
                    trigger={
                      <List.Header as={Link}>
                        {medication.medicationName}
                      </List.Header>
                    }
                  >
                    <MedicationForm
                      pet={pet}
                      reload={reload}
                      veterinarians={veterinarians}
                      medication={medication}
                    />
                  </CreationModals>
                ) : (
                  <List.Header as={Link}>
                    {medication.medicationName}
                  </List.Header>
                )}
                <List.Description>
                  <List bulleted>
                    <List.Item>{medication.reason}</List.Item>
                  </List>
                </List.Description>

                {!isView && windowWidth > 758 && (
                  <List.Content floated="right">
                    <ConfimationModal
                      title="Tem certeza de que deseja apagar esta medicação?"
                      trigger={
                        <Button inverted color="red">
                          <Icon name="trash" />
                          Apagar
                        </Button>
                      }
                      action={handleModal}
                      item={medication}
                    />
                  </List.Content>
                )}
                <List.Description>{medication.medicationType}</List.Description>
                <List.Description>
                  Primeira Administração: {medication.startDate}
                </List.Description>
                <List.Description>
                  Duração do tratamento: {medication.duration}
                </List.Description>
                <List.Description>
                  Dosagem: {medication.dosage}
                </List.Description>
                <VeterinarianInfoModal
                  veterinarian={findObjById(
                    veterinarians,
                    medication.doctorName
                  )}
                  trigger={
                    <List.Header as={Link}>
                      {findNameById(veterinarians, medication.doctorName)}
                    </List.Header>
                  }
                />
                {!isView && windowWidth <= 758 && (
                  <List.Description floated="right">
                    <ConfimationModal
                      title="Tem certeza de que deseja apagar esta medicação?"
                      trigger={
                        <Button inverted color="red">
                          <Icon name="trash" />
                          Apagar
                        </Button>
                      }
                      action={handleModal}
                      item={medication}
                    />
                  </List.Description>
                )}
              </List.Content>
            </List.Item>
          ))}
        </List>
      )}
      {!isView && (
        <CreationModals
          title="Adicionar Medicação"
          trigger={
            <Button
              icon="add"
              content="Adicionar Medicação"
              className="primary-color"
            />
          }
        >
          <MedicationForm
            pet={pet}
            reload={reload}
            veterinarians={veterinarians}
          />
        </CreationModals>
      )}
    </>
  );
};

export default MedicationList;
