import React from "react";
import { Segment, Header, Divider, Icon } from "semantic-ui-react";

const MissionAndVision = () => (
  <Segment basic style={{ padding: "2em 2em" }}>
    <Header as="h3" style={{ fontSize: "2em", textAlign: "center" }}>
      Missão e Visão
    </Header>
    <Divider style={{ maxWidth: "80%", margin: "auto" }} />
    <Segment>
      <Header as="h4">
        <Icon name="world" />
        Missão
      </Header>
      <p style={{ fontSize: "1.33em" }}>
        No coração do <strong className="step-title">PetSeguro</strong> está o
        compromisso de tornar a gestão da saúde e do bem-estar dos animais
        simples e acessível. A nossa missão é oferecer uma plataforma
        centralizada onde os donos possam guardar e aceder rapidamente a
        informações essenciais, como vacinas e desparasitação. Com o{" "}
        <strong className="step-title">PetSeguro</strong>, eliminamos a
        necessidade de transportar documentos físicos, garantindo que os donos
        estejam sempre preparados, até mesmo em situações de emergência.
      </p>
    </Segment>
    <Segment>
      <Header as="h4">
        <Icon name="eye" />
        Visão
      </Header>
      <p style={{ fontSize: "1.33em" }}>
        Sonhamos com um mundo onde os donos de animais possam dedicar-se ao que
        realmente importa: passar tempo de qualidade com os seus companheiros. A
        nossa visão é transformar o{" "}
        <strong className="step-title">PetSeguro</strong> na solução de
        referência no cuidado de animais de estimação, tornando cada aspeto da
        gestão intuitivo, eficaz e sem preocupações. Queremos construir uma
        comunidade de donos responsáveis e felizes, apoiada por uma plataforma
        que valoriza a saúde e o bem-estar dos seus pets.
      </p>
    </Segment>
  </Segment>
);

export default MissionAndVision;
