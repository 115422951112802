import React from "react";
import { Segment, Header, Image, Grid, Divider } from "semantic-ui-react";
import photo from "../../../../resources/other/petCare.png";

const BasicCareHeader = () => (
  <Segment inverted style={{ padding: "2em 0em" }} vertical textAlign="center">
    <Header
      className="step-title"
      as="h3"
      style={{ fontSize: "2em", textAlign: "center" }}
    >
      Guia de Cuidados Básicos para Pets
    </Header>
    <Divider style={{ maxWidth: "80%", margin: "auto" }} />
    <br />
    <Grid container stackable verticalAlign="middle">
      <Grid.Row>
        <Grid.Column width={8}>
          <p style={{ fontSize: "1.33em" }}>
            Cuidar dos animais de estimação vai muito além de uma simples
            responsabilidade; é um ato de amor e dedicação que demonstra o
            quanto eles são importantes nas nossas vidas. Os nossos pets
            oferecem{" "}
            <strong className="step-title">
              companhia, lealdade e conforto
            </strong>
            , e merecem um ambiente seguro, uma alimentação equilibrada e
            cuidados veterinários regulares. Garantir a saúde e o bem-estar dos
            animais é essencial para prevenir doenças, prolongar a sua vida e
            promover uma convivência harmoniosa e feliz.
          </p>
        </Grid.Column>
        <Grid.Column width={8}>
          <Image
            circular
            centered
            size="large"
            src={photo}
            alt="História do PetSeguro"
            style={{
              border: "5px solid #51cb61",
              padding: "4px",
              backgroundColor: "#fff",
            }}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={16}>
          <p style={{ fontSize: "1.33em", textAlign: "center" }}>
            No <strong className="step-title">PetSeguro</strong>, promovemos
            práticas responsáveis para o cuidado dos animais de estimação,
            incentivando uma sociedade mais consciente e respeitadora da vida
            animal. Para além de atender às suas necessidades básicas,
            valorizamos a singularidade de cada pet, disponibilizando recursos e
            informações que ajudam a proporcionar uma vida saudável e feliz.
            Cuidar dos animais não é apenas uma obrigação, mas uma oportunidade
            de{" "}
            <strong className="step-title">
              ensinar empatia e responsabilidade, especialmente às crianças
            </strong>
            . Ao cuidarmos bem dos nossos animais, asseguramos que vivam ao
            nosso lado de forma plena e feliz, enquanto promovemos valores
            essenciais para o futuro.
          </p>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </Segment>
);

export default BasicCareHeader;
