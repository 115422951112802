import React from "react";
import { Segment, Header, Button } from "semantic-ui-react";
import { getCurrentDateTime } from "../../../../resources/lib";
import Grow from "../../../global/Grow";
import { SUPPORT_EMAIL } from "../../../../resources/const/constants";

const FAQCTA = () => {
  const email = SUPPORT_EMAIL;
  const subject = encodeURIComponent(
    `Dúvida de Utilização - PetSeguro - ${getCurrentDateTime()}`
  );
  const body = encodeURIComponent("Escreva a sua mensagem aqui...");

  return (
    <Segment textAlign="center" padded="very">
      <Header as="h2">Não encontrou o que procurava?</Header>
      <p>
        Estamos aqui para ajudar. Entre em contato conosco para mais
        assistência.
      </p>
      <Grow>
        <Button
          className="primary-color"
          as="a"
          href={`mailto:${email}?subject=${subject}&body=${body}`}
        >
          Contacte-nos
        </Button>
      </Grow>
    </Segment>
  );
};

export default FAQCTA;
