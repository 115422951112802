import React, { useState } from "react";
import { Form, Button, Select, Input } from "semantic-ui-react";
import {
  LogErrors,
  getCurrentDateTime,
  notifyError,
  notifySuccess,
  removeId,
} from "../../../resources/lib";
import { measurementsCollection } from "../../../resources/const/firebaseCollections";
import {
  addDataToCollection,
  updateDataByDocId,
} from "../../../resources/firebaseLib";

const WeightForm = ({ pet, reload, measurement, closeModal }) => {
  const [formData, setFormData] = useState(
    measurement
      ? measurement
      : {
          weight: "",
          date: "",
          place: "",
        }
  );
  const isUpdate = measurement ? true : false;

  const placeOptions = [
    { key: "clinic", text: "Clínica", value: "Clínica" },
    { key: "home", text: "Casa", value: "Casa" },
  ];

  const handleChange = (e, { name, value }) =>
    setFormData({ ...formData, [name]: value });

  async function populateCollection(data, uid) {
    try {
      const addData = {
        uid: uid,
        weight: data.weight.trim(),
        date: data.date,
        place: data.place,
        petType: pet.type,
        creationDate: getCurrentDateTime(),
      };

      return await addDataToCollection(measurementsCollection, addData);
    } catch (error) {
      LogErrors(error);
      return false;
    }
  }

  const validObject = () => {
    return (
      formData.weight !== "" && formData.date !== "" && formData.place !== ""
    );
  };

  const handleSubmit = async () => {
    if (!isUpdate) {
      const result = await populateCollection(formData, pet.id);
      if (result) {
        notifySuccess("Pesagem adicionada com sucesso.");
        reload(pet);
      } else {
        notifyError("Falha ao criar a sua Pesagem.");
      }
    } else {
      const result = await updateDataByDocId(
        measurementsCollection,
        formData.id,
        removeId(formData)
      );
      if (result) {
        notifySuccess("Pesagem atualizado com sucesso.");
        reload(pet);
        closeModal();
      } else {
        notifyError("Falha ao atualizar o seu Pesagem.");
        closeModal();
      }
    }
    closeModal(true);
  };

  return (
    <Form
      onSubmit={handleSubmit}
      style={{
        padding: "20px",
      }}
    >
      {/* Peso e Data da Pesagem */}
      <Form.Group widths="equal">
        <Form.Field>
          <label>Peso (kg)</label>
          <Input
            type="number"
            step="0.001"
            placeholder="5.250, 4, 16.7, ..."
            name="weight"
            value={formData.weight}
            onChange={handleChange}
            style={{ borderRadius: "10px" }}
          />
        </Form.Field>
        <Form.Field>
          <label>Data da Pesagem</label>
          <Input
            type="date"
            name="date"
            value={formData.date}
            onChange={handleChange}
            style={{ borderRadius: "10px" }}
          />
        </Form.Field>
      </Form.Group>

      {/* Local da Pesagem */}
      <Form.Field>
        <Form.Field
          control={Select}
          label="Local da Pesagem"
          options={placeOptions}
          placeholder="Selecione o local"
          name="place"
          value={formData.place}
          onChange={handleChange}
          style={{ borderRadius: "10px" }}
        />
      </Form.Field>

      {/* Botão Gravar */}
      <div style={{ textAlign: "center", marginTop: "20px" }}>
        <Button
          type="submit"
          className="primary-color"
          disabled={!validObject()}
          style={{
            padding: "10px 30px",
            borderRadius: "30px",
            fontSize: "1.2em",
          }}
        >
          Gravar
        </Button>
      </div>
    </Form>
  );
};

export default WeightForm;
