import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Icon, Image, Menu } from "semantic-ui-react";
import { useAuth } from "../account/AuthContext";
import logo from "../../resources/new_logo_nbg.png";
import LeftMenu from "./LeftMenu";

const NavbarDesktop = () => {
  const { user, logout } = useAuth();
  const location = useLocation();

  return (
    <Menu
      stackable
      borderless
      inverted
      size="huge"
      className="primary-color"
      style={{ margin: "0px" }}
    >
      {/* Logo como Item Inicial */}
      <Menu.Item as={Link} to="/" className="logo-item">
        <Image
          src={logo}
          alt="PetSeguro Logo"
          size="mini"
          style={{ marginRight: "10px" }}
        />
      </Menu.Item>

      {/* Links Principais */}
      <Menu.Item
        as={Link}
        to="/about"
        name="sobre"
        active={location.pathname === "/about"}
      >
        <Icon name="info circle" /> Sobre
      </Menu.Item>
      <Menu.Item
        as={Link}
        to="/basic-care"
        name="cuidados básicos"
        active={location.pathname === "/basic-care"}
      >
        <Icon name="heartbeat" /> Cuidados Básicos
      </Menu.Item>

      <Menu.Menu position="right">
        <LeftMenu user={user} logout={logout} />
      </Menu.Menu>
    </Menu>
  );
};

export default NavbarDesktop;
