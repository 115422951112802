import React from "react";
import { Link } from "react-router-dom";
import { Container, Grid, Header, List, Segment } from "semantic-ui-react";
import { getCurrentDateTime } from "../../resources/lib";
import { SUPPORT_EMAIL } from "../../resources/const/constants";

const Footer = () => {
  const email = SUPPORT_EMAIL;
  const subject = encodeURIComponent(
    `Contactar - PetSeguro - ${getCurrentDateTime()}`
  );
  const body = encodeURIComponent("Escreva a sua mensagem aqui...");

  return (
    <Segment inverted vertical style={{ padding: "5em 0em" }}>
      <Container>
        <Grid divided inverted stackable>
          <Grid.Row>
            <Grid.Column width={3}>
              <Header inverted as="h4" content="Sobre" />
              <List link inverted>
                <List.Item as={Link} to="/about">
                  Quem Somos
                </List.Item>
                <List.Item
                  as="a"
                  href={`mailto:${email}?subject=${subject}&body=${body}`}
                >
                  Contato
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column width={3}>
              <Header inverted as="h4" content="Serviços" />
              <List link inverted>
                <List.Item as={Link} to="/faq">
                  FAQ
                </List.Item>
                <List.Item as={Link} to="/support">
                  Suporte
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column width={9}>
              <Header as="h4" inverted>
                PetSeguro
              </Header>
              <p>
                A plataforma ideal para ligar-se e cuidar do seu animal de
                estimação com facilidade e eficiência.
              </p>
              <p>
                &copy; PetSeguro 2024 - Powered by{" "}
                <a
                  href="https://tecformosa.pt/"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: "none", color: "#007bff" }}
                >
                  TecFormosa | Serviços Informáticos no Algarve
                </a>
              </p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </Segment>
  );
};

export default Footer;
