import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Container, Form, Input, Segment } from "semantic-ui-react";
import { sendPasswordResetEmail } from "firebase/auth";
import { LogErrors, notifyError, notifySuccess } from "../../resources/lib";
import { auth } from "../../firebase";
import ReCAPTCHA from "react-google-recaptcha";

const ResetPasswordForm = () => {
  const [formData, setFormData] = useState({
    email: "",
  });
  const [isVerified, setIsVerified] = useState(
    process.env.REACT_APP_ENV === "dev"
  );
  const navigate = useNavigate();

  const handleChange = (e, { name, value }) =>
    setFormData({ ...formData, [name]: value });

  const validObject = () => {
    return formData.email.trim() !== "" && isVerified;
  };

  const resetPassword = async (email) => {
    try {
      await sendPasswordResetEmail(auth, email);
      return true;
    } catch (error) {
      LogErrors(error);
      return false;
    }
  };

  const handleSubmit = async () => {
    const result = resetPassword(formData.email);
    if (result) {
      notifySuccess("A sua senha foi reposta com sucesso.");
      navigate("/");
    } else {
      notifyError("Falha a redefinir a senha.");
    }
  };

  return (
    <Container style={{ marginTop: "2em", marginBottom: "2em" }}>
      <Segment basic>
        <Form
          onSubmit={handleSubmit}
          style={{
            padding: "20px",
            maxWidth: "400px",
            margin: "auto",
          }}
        >
          {/* Campo de Email */}
          <Form.Field style={{ marginBottom: "20px" }}>
            <label
              style={{
                fontWeight: "bold",
                fontSize: "1.1em",
                marginBottom: "5px",
              }}
            >
              Email
            </label>
            <Input
              placeholder="Endereço de email"
              name="email"
              type="email"
              value={formData.email}
              onChange={handleChange}
              style={{
                borderRadius: "10px",
                border: "1px solid #ddd",
                padding: "10px",
              }}
            />
          </Form.Field>

          {/* ReCAPTCHA */}
          {process.env.REACT_APP_ENV !== "dev" && (
            <Form.Field style={{ textAlign: "center", marginBottom: "20px" }}>
              <ReCAPTCHA
                size="normal"
                sitekey="6Lc3bqopAAAAAK7LTmTMUUIt2DxubgzS2i5ZOV9l"
                onChange={() => setIsVerified(true)}
                onExpired={() => setIsVerified(false)}
              />
            </Form.Field>
          )}

          {/* Botão Redefinir Senha */}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <Button
              type="submit"
              className="reset-form-button"
              style={{
                padding: "10px 30px",
                borderRadius: "30px",
                fontSize: "1.2em",
                width: "70%",
              }}
              disabled={!validObject()}
            >
              Redefinir Senha
            </Button>
          </div>
        </Form>
      </Segment>
    </Container>
  );
};

export default ResetPasswordForm;
