import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Container,
  Form,
  Input,
  Icon,
  Segment,
  Modal,
} from "semantic-ui-react";
import { useAuth } from "./AuthContext";
import { signInWithEmailAndPassword } from "firebase/auth";
import { LogErrors, notifyError, notifySuccess } from "../../resources/lib";
import { auth } from "../../firebase";
import { getDataBy2Wheres, isLogged } from "../../resources/firebaseLib";
import { usersCollection } from "../../resources/const/firebaseCollections";
import ReCAPTCHA from "react-google-recaptcha";
import ResetPasswordForm from "./ResetPassword";

const LoginForm = () => {
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [isVerified, setIsVerified] = useState(
    process.env.REACT_APP_ENV === "dev"
  );
  const { login } = useAuth();
  const navigate = useNavigate();

  const handleChange = (e, { name, value }) =>
    setFormData({ ...formData, [name]: value });

  const toggleShowPassword = () => setShowPassword(!showPassword);

  const validObject = () => {
    return (
      formData.email.trim() !== "" &&
      formData.password.trim() !== "" &&
      isVerified
    );
  };

  async function loginAccount(data) {
    try {
      await signInWithEmailAndPassword(auth, data.email, data.password);
      return true;
    } catch (error) {
      LogErrors(error);
      return false;
    }
  }

  const handleSubmit = async () => {
    if (isVerified) {
      let result = await loginAccount(formData);
      if (result) {
        if (isLogged(auth)) {
          const userData = await getDataBy2Wheres(
            usersCollection,
            "uid",
            "isDelete",
            auth.currentUser.uid,
            0
          );
          if (userData.length > 0) {
            login({
              uid: userData[0].uid,
              name: userData[0].name,
              email: userData[0].email,
              photo: "icon",
            });
            notifySuccess("Entrou na sua conta com sucesso.");
            navigate("/");
          } else {
            notifyError("A sua conta não existe ou irá ser apagada.");
          }
        } else {
          notifyError("Falha de autenticação no Firebase, contacte o suporte.");
        }
      } else {
        notifyError("Falha a entrar na sua conta.");
      }
    } else {
      notifyError("Por favor, confirme que não é um robô.");
    }
  };

  return (
    <Container style={{ marginTop: "2em", marginBottom: "2em" }}>
      <Segment basic>
        <Form
          onSubmit={handleSubmit}
          style={{
            padding: "20px",
            maxWidth: "400px",
            margin: "auto",
          }}
        >
          {/* Campo de Email */}
          <Form.Field style={{ marginBottom: "20px" }}>
            <label
              style={{
                fontWeight: "bold",
                fontSize: "1.1em",
                marginBottom: "5px",
              }}
            >
              Email
            </label>
            <Input
              placeholder="Endereço de email"
              name="email"
              type="email"
              className="primary-color"
              value={formData.email}
              onChange={handleChange}
              autoComplete="username"
              style={{
                borderRadius: "10px",
                border: "1px solid #ddd",
                padding: "10px",
              }}
            />
          </Form.Field>

          {/* Campo de Senha */}
          <Form.Field style={{ marginBottom: "20px" }}>
            <label
              style={{
                fontWeight: "bold",
                fontSize: "1.1em",
                marginBottom: "5px",
              }}
            >
              Senha
            </label>
            <Input
              placeholder="Senha"
              name="password"
              type={showPassword ? "text" : "password"}
              className="primary-color-input"
              value={formData.password}
              onChange={handleChange}
              icon={
                <Icon
                  name={showPassword ? "eye slash outline" : "eye"}
                  link
                  onClick={toggleShowPassword}
                />
              }
              autoComplete="current-password"
              style={{
                borderRadius: "10px",
                border: "1px solid #ddd",
                padding: "10px",
              }}
            />
          </Form.Field>

          {/* Esqueceu sua senha */}
          <Form.Field style={{ textAlign: "center", marginBottom: "20px" }}>
            <Modal
              trigger={
                <span
                  style={{
                    color: "#5dbcd2",
                    cursor: "pointer",
                    textDecoration: "underline",
                    fontSize: "1em",
                  }}
                >
                  Esqueceu sua senha?
                </span>
              }
              onClose={() => setOpen(false)}
              onOpen={() => setOpen(true)}
              open={open}
              size="tiny"
              style={{
                borderRadius: "15px",
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
              }}
            >
              <Modal.Header
                className="primary-color"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderRadius: "15px 15px 0 0",
                  padding: "15px 20px",
                }}
              >
                <span
                  style={{
                    fontSize: "1.5em",
                    fontWeight: "bold",
                    color: "#ffffff",
                  }}
                >
                  <Icon name="key" /> Redefinir Senha
                </span>
                <Icon
                  name="close"
                  size="large"
                  style={{ cursor: "pointer", color: "white" }}
                  onClick={() => setOpen(false)}
                />
              </Modal.Header>
              <Modal.Content
                scrolling
                style={{
                  padding: "20px",
                  backgroundColor: "#f9f9f9",
                  borderRadius: "0 0 15px 15px",
                }}
              >
                <ResetPasswordForm />
              </Modal.Content>
            </Modal>
          </Form.Field>

          {/* ReCAPTCHA */}
          {process.env.REACT_APP_ENV !== "dev" && (
            <Form.Field style={{ textAlign: "center", marginBottom: "20px" }}>
              <ReCAPTCHA
                size="normal"
                sitekey="6Lc3bqopAAAAAK7LTmTMUUIt2DxubgzS2i5ZOV9l"
                onChange={() => setIsVerified(true)}
                onExpired={() => setIsVerified(false)}
              />
            </Form.Field>
          )}

          {/* Botão Entrar */}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <Button
              type="submit"
              className="register-form-button"
              style={{
                padding: "10px 30px",
                borderRadius: "30px",
                fontSize: "1.2em",
                width: "70%",
              }}
              disabled={!validObject()}
            >
              Entrar
            </Button>
          </div>
        </Form>
      </Segment>
    </Container>
  );
};

export default LoginForm;
