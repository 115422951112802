import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Icon, Menu } from "semantic-ui-react";
import { useAuth } from "../account/AuthContext";
import LeftMenu from "./LeftMenu";

const NavbarMobile = () => {
  const { user, logout } = useAuth();
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => setMenuOpen(!menuOpen);

  return (
    <>
      {/* Menu para dispositivos móveis */}
      <Menu
        stackable
        borderless
        inverted
        style={{ margin: "0px" }}
        size="huge"
        className="primary-color square"
      >
        {/* Botão de hambúrguer visível no mobile */}
        <Menu.Item className="hamburger-menu" onClick={toggleMenu}>
          <Icon name="bars" size="large" />
        </Menu.Item>

        {/* Itens do menu (escondidos no mobile até o hambúrguer ser clicado) */}
        <div className={`menu-items ${menuOpen ? "open" : "closed"}`}>
          <Menu.Item as={Link} to="/" name="início" onClick={toggleMenu} />
          <Menu.Item as={Link} to="/about" name="sobre" onClick={toggleMenu} />
          <Menu.Item
            as={Link}
            to="/basic-care"
            name="cuidados básicos"
            onClick={toggleMenu}
          />

          <Menu.Menu position="right">
            <LeftMenu user={user} logout={logout} />
          </Menu.Menu>
        </div>
      </Menu>
    </>
  );
};

export default NavbarMobile;
