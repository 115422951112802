import React, { useState } from "react";
import { Modal, Button, Icon } from "semantic-ui-react";

const ConfimationModal = ({ title, trigger: Trigger, action, item }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleModal = (e, value) => {
    e.preventDefault();
    handleClose();
    if (action) item ? action(value, item) : action(value);
  };

  return (
    <Modal
      trigger={<div onClick={handleOpen}>{Trigger}</div>}
      open={open}
      onClose={handleClose}
      size="tiny"
      style={{
        borderRadius: "15px",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
      }}
    >
      {/* Cabeçalho */}
      <Modal.Header
        className="primary-color"
        style={{
          display: "flex",
          alignItems: "center",
          gap: "10px",
          padding: "15px 20px",
        }}
      >
        <Icon name="trash alternate outline" size="large" />{" "}
        {/* Ícone de Exclusão */}
        <span style={{ fontSize: "1.1em", fontWeight: "bold", color: "white" }}>
          Confirmar Exclusão
        </span>
      </Modal.Header>

      {/* Conteúdo */}
      <Modal.Content
        style={{
          textAlign: "center",
          fontSize: "1.2em",
          padding: "20px",
        }}
      >
        <p>
          {title
            ? title
            : "Tem certeza de que deseja apagar este pet? Esta ação não pode ser desfeita."}
        </p>
      </Modal.Content>

      {/* Ações */}
      <Modal.Actions
        className="tertiary-color"
        style={{
          display: "flex",
          justifyContent: "center",
          gap: "15px",
          padding: "15px 20px",
        }}
      >
        <Button
          negative
          onClick={(e) => handleModal(e, false)}
          style={{
            borderRadius: "30px",
            padding: "10px 20px",
            fontSize: "1em",
          }}
        >
          <Icon name="cancel" /> Não
        </Button>
        <Button
          positive
          onClick={(e) => handleModal(e, true)}
          style={{
            borderRadius: "30px",
            padding: "10px 20px",
            fontSize: "1em",
          }}
        >
          <Icon name="check" /> Sim
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default ConfimationModal;
