import React, { useEffect, useState } from "react";
import { Card, Image, Icon, Button } from "semantic-ui-react";
import photo from "../../../../resources/avatars/rodent.png";
import ConfimationModal from "../../../modals/ConfimationModal";
import { updateDataByDocId } from "../../../../resources/firebaseLib";
import {
  calculatePetAge,
  notifyError,
  notifySuccess,
  removeId,
} from "../../../../resources/lib";
import { rodentsCollection } from "../../../../resources/const/firebaseCollections";
import { useAuth } from "../../../account/AuthContext";
import EditPetModal from "../../../modals/EditPetModal";
import PetProfilePreviewModal from "../../../modals/PetProfilePreviewModal";
import { getAllFilesFromFolder } from "../../../../resources/storageLib";

const RodentCard = ({ pet, reload }) => {
  const { user } = useAuth();
  const [isFlipped, setIsFlipped] = useState(false); // Estado para controle do flip
  const [petPhoto, setPetPhoto] = useState(null);

  useEffect(() => {
    getPetPhotos(pet);
  }, [pet]);

  async function getPetPhotos(pet) {
    const data = await getAllFilesFromFolder(`photos/${pet.id}`);
    if (data.length > 0) setPetPhoto(data[0].url);
  }

  const handleFlip = () => {
    setIsFlipped(!isFlipped); // Inverte o estado de flip ao clicar no card
  };

  const handleModal = async (value) => {
    if (value) {
      pet.isDelete = 1;
      const result = await updateDataByDocId(
        rodentsCollection,
        pet.id,
        removeId(pet)
      );
      if (result) {
        reload(user);
        notifySuccess(`O seu pet ${pet.name}, foi apagado.`);
      } else {
        notifyError(`Falha a apagar este pet: ${pet.name}`);
      }
    }
  };

  return (
    <Card
      className="primary-color"
      onClick={handleFlip}
      style={{
        margin: "auto",
        width: "300px",
        height: "350px",
        position: "relative",
        cursor: "pointer",
        perspective: "1000px",
      }}
    >
      {/* Parte da frente do card (com a imagem) */}
      <div
        style={{
          transform: isFlipped ? "rotateY(180deg)" : "rotateY(0)",
          transition: "transform 0.6s",
          width: "100%",
          height: "100%",
          position: "absolute",
          backfaceVisibility: "hidden",
        }}
      >
        <Image
          src={petPhoto == null ? photo : petPhoto}
          wrapped
          ui={false}
          className="tertiary-color image-div"
        />
        <Card.Content>
          <Card.Header>{pet.name}</Card.Header>
          <Card.Meta>
            <span className="date">{calculatePetAge(pet.birthDate)}</span>
          </Card.Meta>
          <Card.Content extra>
            <Icon name={pet.sex === "male" ? "man" : "woman"} />
            {pet.species}
          </Card.Content>
        </Card.Content>
      </div>

      {/* Parte de trás do card (com as 3 opções de ações) */}
      <div
        style={{
          transform: isFlipped ? "rotateY(0)" : "rotateY(180deg)",
          transition: "transform 0.6s",
          width: "100%",
          height: "100%",
          position: "absolute",
          backfaceVisibility: "hidden",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: "20px",
          backgroundColor: "#5dbcd2",
        }}
      >
        <PetProfilePreviewModal
          pet={pet}
          petPhoto={petPhoto}
          trigger={<Button color="blue" icon="eye" content="Ver Perfil" />}
        />
        <br />
        <EditPetModal
          pet={pet}
          trigger={<Button color="green" icon="edit" content="Editar" />}
          reload={reload}
        />
        <br />
        <ConfimationModal
          pet={pet}
          trigger={<Button color="red" icon="delete" content="Excluir" />}
          action={handleModal}
        />
      </div>
    </Card>
  );
};

export default RodentCard;
