import React, { useState, cloneElement } from "react";
import { Modal, Icon } from "semantic-ui-react";

const CreationModals = ({ title, trigger: Trigger, children }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const childrenWithProps = cloneElement(children, { closeModal: handleClose });

  return (
    <Modal
      trigger={<div onClick={handleOpen}>{Trigger}</div>}
      open={open}
      onClose={handleClose}
      size="small"
      style={{
        borderRadius: "15px",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
      }}
    >
      {/* Cabeçalho */}
      <Modal.Header
        className="primary-color"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "15px 20px",
        }}
      >
        {/* Título com Ícone */}
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <Icon name="plus" size="large" style={{ color: "white" }} />
          <span
            style={{ fontSize: "1.5em", fontWeight: "bold", color: "white" }}
          >
            {title}
          </span>
        </div>

        {/* Ícone de Fechar */}
        <Icon
          name="close"
          size="large"
          style={{
            cursor: "pointer",
            color: "white",
          }}
          onClick={handleClose}
        />
      </Modal.Header>

      {/* Conteúdo */}
      <Modal.Content
        scrolling
        style={{
          minHeight: "300px",
          padding: "20px",
        }}
      >
        {childrenWithProps}
      </Modal.Content>
    </Modal>
  );
};

export default CreationModals;
