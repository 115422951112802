import React, { useEffect, useState } from "react";
import { Modal, Tab, Button, Icon, Divider } from "semantic-ui-react";
import {
  LogErrors,
  notifyError,
  notifySuccess,
  sortObjectsByProperty,
} from "../../resources/lib";
import {
  chipsCollection,
  dewormingsCollection,
  measurementsCollection,
  medicationsCollection,
  surgeriesCollection,
  vaccinesCollection,
  vetsCollection,
} from "../../resources/const/firebaseCollections";
import { getDataBy2Wheres } from "../../resources/firebaseLib";
import VaccinationList from "../vet/VaccinationList";
import DewormingList from "../vet/DewormingList";
import MedicationList from "../vet/MedicationList";
import SurgeryList from "../vet/SurgeryList";
import MeasurementsList from "../vet/MeasurementsList";
import ChipInfo from "../vet/views/ChipInfo";
import ResponsiveTab from "../global/ResponsiveTab";
import DogProfilePreview from "../vet/views/animals/DogProfilePreview";
import CatProfilePreview from "../vet/views/animals/CatProfilePreview";
import BirdProfilePreview from "../vet/views/animals/BirdProfilePreview";
import RodentProfilePreview from "../vet/views/animals/RodentProfilePreview";
import ReptileProfilePreview from "../vet/views/animals/ReptileProfilePreview";

const PetProfilePreviewModal = ({
  pet,
  petPhoto,
  trigger: Trigger,
  action,
}) => {
  const [maxHeight, setMaxHeight] = useState("500px");
  const [open, setOpen] = useState(false);

  const [veterinarians, setVeterinarians] = useState([]);
  const [vaccines, setVaccines] = useState([]);
  const [dewormings, setDewormings] = useState([]);
  const [medications, setMedications] = useState([]);
  const [surgeries, setSurgeries] = useState([]);
  const [measurements, setMeasurements] = useState([]);
  const [chip, setChip] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await getVets(pet);
        await getVaccines(pet);
        await getDewormings(pet);
        await getMedications(pet);
        await getSurgeries(pet);
        await getMeasurements(pet);
        await getChip(pet);
      } catch (error) {
        LogErrors(error);
        notifyError("Falha a carregar os dados do pet.");
      }
    };

    fetchData();
  }, [pet]);

  useEffect(() => {
    const updateMaxHeight = () => {
      const newMaxHeight = `${window.innerHeight - 200}px`;
      setMaxHeight(newMaxHeight);
    };

    window.addEventListener("resize", updateMaxHeight);
    updateMaxHeight();
    return () => window.removeEventListener("resize", updateMaxHeight);
  }, []);

  async function getVets(pet) {
    const data = await getDataBy2Wheres(
      vetsCollection,
      "uid",
      "petType",
      pet.id,
      pet.type
    );
    setVeterinarians(sortObjectsByProperty(data, "creationDate", "desc"));
  }

  async function getVaccines(pet) {
    const data = await getDataBy2Wheres(
      vaccinesCollection,
      "uid",
      "petType",
      pet.id,
      pet.type
    );
    setVaccines(sortObjectsByProperty(data, "givenDate", "desc"));
  }

  async function getDewormings(pet) {
    const data = await getDataBy2Wheres(
      dewormingsCollection,
      "uid",
      "petType",
      pet.id,
      pet.type
    );
    setDewormings(sortObjectsByProperty(data, "creationDate", "desc"));
  }

  async function getMedications(pet) {
    const data = await getDataBy2Wheres(
      medicationsCollection,
      "uid",
      "petType",
      pet.id,
      pet.type
    );
    setMedications(sortObjectsByProperty(data, "creationDate", "desc"));
  }

  async function getSurgeries(pet) {
    const data = await getDataBy2Wheres(
      surgeriesCollection,
      "uid",
      "petType",
      pet.id,
      pet.type
    );
    setSurgeries(sortObjectsByProperty(data, "creationDate", "desc"));
  }

  async function getMeasurements(pet) {
    const data = await getDataBy2Wheres(
      measurementsCollection,
      "uid",
      "petType",
      pet.id,
      pet.type
    );
    setMeasurements(sortObjectsByProperty(data, "creationDate", "desc"));
  }

  async function getChip(pet) {
    const data = await getDataBy2Wheres(
      chipsCollection,
      "uid",
      "petType",
      pet.id,
      pet.type
    );
    if (data.length > 0) setChip(data[0]);
    else setChip(null);
  }

  const handleOpen = () => {
    setOpen(true);
    action(true);
  };
  const handleClose = () => {
    setOpen(false);
    action(false);
  };

  const handleShareClick = () => {
    const urlToShare = `${window.location.origin}/pet/share/${pet.type}/${pet.id}`;

    navigator.clipboard
      .writeText(urlToShare)
      .then(() => {
        notifySuccess("URL copiado para a área de transferência!");
      })
      .catch((err) => {
        LogErrors(err);
        notifyError("Erro ao copiar URL para a área de transferência.");
      });
  };

  const renderProfile = (pet) => {
    switch (pet.type) {
      case "cat":
        return (
          <CatProfilePreview
            pet={pet}
            petPhoto={petPhoto}
            measurements={measurements}
          />
        );
      case "bird":
        return (
          <BirdProfilePreview
            pet={pet}
            petPhoto={petPhoto}
            measurements={measurements}
          />
        );
      case "rodent":
        return (
          <RodentProfilePreview
            pet={pet}
            petPhoto={petPhoto}
            measurements={measurements}
          />
        );
      case "reptile":
        return (
          <ReptileProfilePreview
            pet={pet}
            petPhoto={petPhoto}
            measurements={measurements}
          />
        );
      default:
        return (
          <DogProfilePreview
            pet={pet}
            petPhoto={petPhoto}
            measurements={measurements}
          />
        );
    }
  };

  // Tab panes estrutura
  const panes = [
    {
      menuItem: "Vacinação",
      render: () => (
        <Tab.Pane>
          <div>
            <VaccinationList
              pet={pet}
              vaccines={vaccines}
              veterinarians={veterinarians}
              isView={true}
              reload={() => {}}
            />
          </div>
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Desparasitação",
      render: () => (
        <Tab.Pane>
          <div>
            <DewormingList
              pet={pet}
              dewormings={dewormings}
              isView={true}
              reload={() => {}}
            />
          </div>
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Medicação",
      render: () => (
        <Tab.Pane>
          <div>
            <MedicationList
              pet={pet}
              medications={medications}
              veterinarians={veterinarians}
              isView={true}
              reload={() => {}}
            />
          </div>
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Cirurgias",
      render: () => (
        <Tab.Pane>
          <div>
            <SurgeryList
              pet={pet}
              surgeries={surgeries}
              veterinarians={veterinarians}
              isView={true}
              reload={() => {}}
            />
          </div>
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Pesagens",
      render: () => (
        <Tab.Pane>
          <div>
            <MeasurementsList
              pet={pet}
              measurements={measurements}
              isView={true}
              reload={() => {}}
            />
          </div>
        </Tab.Pane>
      ),
    },
    pet.type === "dog" || pet.type === "cat" ? (
      {
        menuItem: "Chip",
        render: () => (
          <Tab.Pane>
            <div>
              <ChipInfo chipInfo={chip} />
            </div>
          </Tab.Pane>
        ),
      }
    ) : (
      <></>
    ),
  ];

  return (
    <Modal
      trigger={<div onClick={handleOpen}>{Trigger}</div>}
      open={open}
      onClose={handleClose}
      size="small"
      style={{
        borderRadius: "15px",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
        overflow: "hidden",
      }}
    >
      {/* Cabeçalho */}
      <Modal.Header
        className="primary-color"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "15px 20px",
        }}
      >
        <span style={{ fontSize: "1.5em", fontWeight: "bold" }}>
          <Icon name="paw" /> {pet.name}
        </span>
        <Icon
          name="close"
          size="large"
          style={{ cursor: "pointer", color: "white" }}
          onClick={handleClose}
        />
      </Modal.Header>

      {/* Conteúdo da Modal */}
      <div style={{ maxHeight: maxHeight, overflowY: "auto", padding: "20px" }}>
        <Modal.Content image scrolling>
          {renderProfile(pet)}
        </Modal.Content>
        <Divider />
        <ResponsiveTab panes={panes} />
      </div>

      {/* Ações */}
      <Modal.Actions
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "15px 20px",
          backgroundColor: "#b4e391",
          borderTop: "1px solid #e0e0e0",
        }}
      >
        <Button
          primary
          onClick={handleShareClick}
          style={{
            borderRadius: "30px",
            padding: "10px 20px",
          }}
        >
          <Icon name="share alternate" /> Partilhar
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default PetProfilePreviewModal;
