import React, { useState, useEffect } from "react";
import {
  Card,
  Container,
  Dimmer,
  Grid,
  Icon,
  Loader,
  Segment,
} from "semantic-ui-react";
import AnimalModal from "../../modals/AnimalModal";
import HomePetBanner from "./components/HomePetBanner";
import { useAuth } from "../../account/AuthContext";
import { getDataBy2Wheres } from "../../../resources/firebaseLib";
import {
  birdsCollection,
  catsCollection,
  dogsCollection,
  reptilesCollection,
  rodentsCollection,
} from "../../../resources/const/firebaseCollections";
import AppearOnScroll from "../../global/AppearOnScroll";
import DogCard from "./cards/DogCard";
import CatCard from "./cards/CatCard";
import BirdCard from "./cards/BirdCard";
import RodentCard from "./cards/RodentCard";
import ReptileCard from "./cards/ReptileCard";

const HomePets = () => {
  const { user } = useAuth();
  const [onLoad, setOnLoad] = useState(false);
  const [pets, setPets] = useState([]);

  useEffect(() => {
    getPets(user);
  }, [user]);

  async function getPets(user) {
    setOnLoad(true);
    const dogs = await getDataBy2Wheres(
      dogsCollection,
      "uid",
      "isDelete",
      user.uid,
      0
    );
    const cats = await getDataBy2Wheres(
      catsCollection,
      "uid",
      "isDelete",
      user.uid,
      0
    );
    const birds = await getDataBy2Wheres(
      birdsCollection,
      "uid",
      "isDelete",
      user.uid,
      0
    );
    const rodents = await getDataBy2Wheres(
      rodentsCollection,
      "uid",
      "isDelete",
      user.uid,
      0
    );
    const reptiles = await getDataBy2Wheres(
      reptilesCollection,
      "uid",
      "isDelete",
      user.uid,
      0
    );
    const pets = [...dogs, ...cats, ...birds, ...rodents, ...reptiles];
    setPets(pets);
    setOnLoad(false);
  }

  const selectCard = (pet) => {
    switch (pet.type) {
      case "dog":
        return <DogCard pet={pet} reload={getPets} />;
      case "cat":
        return <CatCard pet={pet} reload={getPets} />;
      case "bird":
        return <BirdCard pet={pet} reload={getPets} />;
      case "rodent":
        return <RodentCard pet={pet} reload={getPets} />;
      case "reptile":
        return <ReptileCard pet={pet} reload={getPets} />;
      default:
        return <DogCard pet={pet} reload={getPets} />;
    }
  };

  return (
    <Container fluid>
      <HomePetBanner />
      {onLoad && (
        <Segment basic style={{ minHeight: "36.5vh" }}>
          <Dimmer active inverted style={{ backgroundColor: "transparent" }}>
            <Loader indeterminate size="massive" style={{ color: "black" }}>
              Carregando...
            </Loader>
          </Dimmer>
        </Segment>
      )}
      {!onLoad && (
        <Grid
          columns={5}
          padded
          style={{ minHeight: "39.4vh" }}
          textAlign="center"
          stackable
        >
          <Grid.Row>
            {pets.map((pet, index) => (
              <Grid.Column key={index} style={{ margin: "5px" }}>
                <AppearOnScroll delay={index * 2}>
                  {selectCard(pet)}
                </AppearOnScroll>
              </Grid.Column>
            ))}
            <Grid.Column
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                margin: "5px",
              }}
            >
              <AnimalModal
                title="Detalhes do Animal de Estimação"
                trigger={
                  <Card className="secondary-color" style={{ margin: "auto" }}>
                    <Card.Content textAlign="center">
                      <Icon name="add" size="huge" />
                      <Card.Header>
                        Adicionar um Animal de Estimação
                      </Card.Header>
                    </Card.Content>
                  </Card>
                }
                reload={getPets}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      )}
    </Container>
  );
};

export default HomePets;
