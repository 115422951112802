import React from "react";
import { Container } from "semantic-ui-react";
import FAQHeader from "./components/FAQHeader";
import FAQItem from "./components/FAQItem";
import FAQCTA from "./components/FAQCTA";
import AppearOnScroll from "../../global/AppearOnScroll";

const faqs = [
  {
    question: "Como criar uma conta no PetSeguro?",
    answer:
      "Para criar uma conta no PetSeguro, clique no botão 'Registar' na barra de navegação e preencha o formulário com as suas informações. O processo é rápido e fácil!",
  },
  {
    question: "Como adicionar um animal de estimação à minha conta?",
    answer:
      "Depois de iniciar sessão, vá à página inicial e clique em '+ Adicionar um animal de estimação'. Escolha o tipo de animal (cão, gato, ave, roedor ou réptil) e preencha os campos necessários.",
  },
  {
    question: "Como editar informações do meu animal de estimação?",
    answer:
      "Na página inicial, clique no animal de estimação que pretende editar. Depois, selecione o ícone de lápis para atualizar os dados do seu animal de estimação.",
  },
  {
    question: "Como adicionar informações de saúde ao perfil do meu animal?",
    answer:
      "Após adicionar o seu animal de estimação, vá à página de edição do perfil. Nos separadores disponíveis, pode registar dados como veterinário, vacinação, desparasitação, medicação, cirurgias, pesagens e o número de chip para cães e gatos. Clique em '+ Adicionar...' no separador correspondente.",
  },
  {
    question:
      "Preciso adicionar um veterinário antes de registar vacinas ou medicação?",
    answer:
      "Sim, é obrigatório adicionar pelo menos um veterinário ao perfil do seu animal de estimação antes de registar vacinação, medicação ou cirurgias.",
  },
  {
    question:
      "Como visualizar um resumo das informações do meu animal de estimação?",
    answer:
      "Na página inicial, clique no animal de estimação que pretende consultar e selecione o ícone de olho aberto. Assim, pode aceder a um resumo detalhado de todas as informações registadas.",
  },
  {
    question: "É possível partilhar as informações do meu animal de estimação?",
    answer:
      "Sim, na vista detalhada do perfil do seu animal de estimação, clique no botão 'Partilhar' para enviar as informações a familiares, veterinários ou outras pessoas.",
  },
  {
    question: "Como apago informações de saúde do meu animal de estimação?",
    answer:
      "Para apagar dados como vacinação ou medicação, aceda ao registo específico e clique no botão 'Apagar' associado à entrada que pretende remover.",
  },
  {
    question: "Como remover um animal de estimação da minha conta?",
    answer:
      "Na página inicial, selecione o animal de estimação que pretende remover e clique no ícone de 'X' para apagar o perfil do animal.",
  },
];

const FAQPage = () => (
  <Container fluid>
    <FAQHeader />
    <br />
    <Container>
      {faqs.map((faq, index) => (
        <AppearOnScroll key={index} delay={index}>
          <FAQItem question={faq.question} answer={faq.answer} />
        </AppearOnScroll>
      ))}
      <FAQCTA />
    </Container>
    <br />
  </Container>
);

export default FAQPage;
