import React, { useState } from "react";
import { Modal, Dropdown, Icon } from "semantic-ui-react";
import DogForm from "../petForms/DogForm";
import CatForm from "../petForms/CatForm";
import RodentForm from "../petForms/RodentForm";
import BirdForm from "../petForms/BirdForm";
import ReptileForm from "../petForms/ReptileForm";

const AnimalModal = ({ title, trigger: Trigger, reload }) => {
  const [open, setOpen] = useState(false);
  const [selectedPet, setSelectedPet] = useState("");

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setSelectedPet("");
    setOpen(false);
  };

  const petOptions = [
    { key: "dog", value: "dog", text: "Cão" },
    { key: "cat", value: "cat", text: "Gato" },
    { key: "rodent", value: "rodent", text: "Roedor" },
    { key: "bird", value: "bird", text: "Ave" },
    { key: "reptile", value: "reptile", text: "Réptil" },
  ];

  const handlePetChange = (e, { value }) => setSelectedPet(value);

  const renderForm = () => {
    switch (selectedPet) {
      case "dog":
        return <DogForm reload={reload} closeModal={handleClose} />;
      case "cat":
        return <CatForm reload={reload} closeModal={handleClose} />;
      case "rodent":
        return <RodentForm reload={reload} closeModal={handleClose} />;
      case "bird":
        return <BirdForm reload={reload} closeModal={handleClose} />;
      case "reptile":
        return <ReptileForm reload={reload} closeModal={handleClose} />;
      default:
        return <div></div>;
    }
  };

  return (
    <Modal
      trigger={<div onClick={handleOpen}>{Trigger}</div>}
      open={open}
      onClose={handleClose}
      size="small"
      style={{
        borderRadius: "15px",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
      }}
    >
      {/* Cabeçalho */}
      <Modal.Header
        className="primary-color"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "15px 20px",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <Icon name="paw" size="large" /> {/* Ícone de animal */}
          <span
            style={{ fontSize: "1.1em", fontWeight: "bold", color: "white" }}
          >
            {title}
          </span>
        </div>
        {/* Ícone de Fechar */}
        <Icon
          name="close"
          size="large"
          style={{
            cursor: "pointer",
            color: "white",
          }}
          onClick={handleClose}
        />
      </Modal.Header>

      {/* Conteúdo */}
      <Modal.Content
        style={{
          minHeight: "300px",
          padding: "20px",
        }}
      >
        <label
          style={{
            fontSize: "1em",
            color: "#333",
            marginBottom: "10px",
            display: "block",
          }}
        >
          Tipo de Pet
        </label>
        <Dropdown
          placeholder="Selecione o tipo de pet"
          fluid
          selection
          options={petOptions}
          onChange={handlePetChange}
          style={{
            fontSize: "1em",
            marginBottom: "20px",
          }}
        />
        {renderForm()}
      </Modal.Content>
    </Modal>
  );
};

export default AnimalModal;
