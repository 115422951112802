import React from "react";
import { Accordion, Segment } from "semantic-ui-react";

const ReptileCareDetails = () => {
  const panels = [
    {
      key: "veterinary-exam",
      title: "Exame Veterinário Inicial",
      content: {
        content: (
          <div>
            <p>
              Ao adquirir um novo réptil, leve-o a uma consulta inicial com um
              veterinário especializado em répteis. Este check-up permitirá
              avaliar a saúde do animal e receber orientações específicas para a
              espécie.
            </p>
          </div>
        ),
      },
    },
    {
      key: "regular-checkups",
      title: "Check-ups Regulares",
      content: {
        content: (
          <div>
            <p>
              Anualmente: Realizar consultas veterinárias regulares é essencial
              para monitorizar a saúde do réptil e prevenir problemas.
            </p>
          </div>
        ),
      },
    },
    {
      key: "adequate-habitat",
      title: "Habitat Adequado",
      content: {
        content: (
          <div>
            <p>
              Temperatura e humidade: Mantenha um ambiente controlado com as
              temperaturas e humidades adequadas à espécie, o que pode incluir
              lâmpadas de aquecimento e dispositivos de humidade.
            </p>
            <p>
              Substrato adequado: Utilize um substrato apropriado para a
              espécie, que não cause impactação se ingerido e que facilite a
              higiene do habitat.
            </p>
          </div>
        ),
      },
    },
    {
      key: "nutrition",
      title: "Nutrição Adequada",
      content: {
        content: (
          <div>
            <p>
              Dieta específica para a espécie: Alimente o seu réptil com uma
              dieta apropriada, que pode incluir insetos, vegetais, frutas ou
              alimentos comerciais, dependendo das necessidades da espécie.
            </p>
            <p>
              Suplementos: Muitos répteis necessitam de suplementos de cálcio e
              vitaminas, como vitamina D3, para prevenir deficiências
              nutricionais.
            </p>
          </div>
        ),
      },
    },
    {
      key: "environment-maintenance",
      title: "Manutenção do Ambiente",
      content: {
        content: (
          <div>
            <p>
              Limpeza regular: Limpe o habitat frequentemente para evitar o
              acumular de bactérias e fungos, removendo fezes e restos de
              alimentos diariamente.
            </p>
          </div>
        ),
      },
    },
    {
      key: "parasite-prevention",
      title: "Prevenção de Parasitas",
      content: {
        content: (
          <div>
            <p>
              Exames periódicos: Realize exames regulares para detetar parasitas
              internos e externos, especialmente se o réptil teve contacto com
              outros animais ou ambientes externos.
            </p>
          </div>
        ),
      },
    },
  ];

  return (
    <Segment basic>
      <Accordion panels={panels} styled fluid />
    </Segment>
  );
};

export default ReptileCareDetails;
