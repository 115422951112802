import React, { useEffect, useState } from "react";
import { getCurrentMonthCareEvents } from "../../../../resources/lib";
import { Card, Icon, Header, Container } from "semantic-ui-react";

const SeasonalCareAlert = () => {
  const [careItems, setCareItems] = useState([]);

  useEffect(() => {
    const items = getCurrentMonthCareEvents();
    setCareItems(items);
  }, []);

  const formatDescription = (text) => {
    let parts = text.split(":");
    return (
      <p>
        <strong>{parts[0]}:</strong> {parts[1]}
      </p>
    );
  };

  if (careItems.length === 0) {
    return null;
  }

  return (
    <Container
      style={{
        margin: "10px auto",
        padding: "20px",
        backgroundColor: "#F5F5F5", // Cor neutra do site
        borderRadius: "12px",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Header as="h2" icon textAlign="center" style={{ marginBottom: "20px" }}>
        <Icon
          name="exclamation triangle"
          circular
          style={{
            backgroundColor: "#FFD700", // Cor secundária
            color: "#FFFFFF", // Ícone em branco
            padding: "10px",
          }}
        />
        <Header.Content style={{ color: "#5DBCD2" }}>
          Cuidados Sazonais para o Seu Animal de Estimação
        </Header.Content>
        <Header.Subheader style={{ color: "#333", marginTop: "10px" }}>
          Descubra os cuidados essenciais e os perigos específicos que esta
          época do ano pode trazer para a saúde e bem-estar do seu animal.
        </Header.Subheader>
      </Header>
      <Card.Group
        itemsPerRow={careItems.length > 1 ? 2 : 1}
        stackable
        style={{ marginTop: "20px" }}
      >
        {careItems.map((item, index) => (
          <Card
            key={index}
            style={{
              border: "1px solid #5DBCD2", // Cor primária
              borderRadius: "8px",
              boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Card.Content>
              <Card.Header
                style={{
                  color: "#5DBCD2", // Cor primária
                  fontWeight: "bold",
                }}
              >
                {item.title}
              </Card.Header>
              <Card.Description style={{ marginTop: "10px", color: "#666" }}>
                {formatDescription(item.description)}
              </Card.Description>
            </Card.Content>
          </Card>
        ))}
      </Card.Group>
    </Container>
  );
};

export default SeasonalCareAlert;
