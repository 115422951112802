import React, { useEffect, useState } from "react";
import { Icon, Image } from "semantic-ui-react";
import { calculatePetAge } from "../../../../resources/lib";
import photo from "../../../../resources/avatars/bird.png";

const BirdProfilePreview = ({ pet, petPhoto, measurements }) => {
  // Estado para verificar se é mobile
  const [isMobile, setIsMobile] = useState(window.innerWidth < 769);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 769);
    };

    // Adiciona o evento de redimensionamento
    window.addEventListener("resize", handleResize);

    // Remove o evento ao desmontar
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: isMobile ? "column" : "row", // Layout responsivo
        alignItems: isMobile ? "center" : "flex-start",
        gap: "20px",
        padding: "20px",
        backgroundColor: "#f9f9f9",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
      }}
    >
      {/* Imagem do Pássaro */}
      <Image
        wrapped
        src={petPhoto == null ? photo : petPhoto}
        alt="Foto do Pássaro"
        circular
        style={{
          maxWidth: isMobile ? "150px" : "250px", // Ajusta tamanho para mobile
          maxHeight: isMobile ? "150px" : "250px",
          objectFit: "cover",
          borderRadius: "50%",
          overflow: "hidden",
          border: "2px solid #5dbcd2",
        }}
      />

      {/* Caixa de Informações */}
      <div style={{ flex: 1, textAlign: isMobile ? "center" : "left" }}>
        {/* Nome do Pássaro (Título) */}
        <h2 style={{ margin: "0 0 10px 0", color: "#5dbcd2" }}>
          {pet.name} <Icon name={pet.sex === "male" ? "man" : "woman"} />
        </h2>

        {/* Outras Informações */}
        <p style={{ margin: "5px 0", fontSize: "1em", color: "#333" }}>
          <strong>Espécie:</strong> {pet.species}
        </p>
        <p style={{ margin: "5px 0", fontSize: "1em", color: "#333" }}>
          <strong>Idade:</strong> {calculatePetAge(pet.birthDate)}
        </p>
        <p style={{ margin: "5px 0", fontSize: "1em", color: "#333" }}>
          <strong>Cor das Penas:</strong> {pet.featherColor}
        </p>
        {measurements.length > 0 && (
          <p style={{ margin: "5px 0", fontSize: "1em", color: "#333" }}>
            <strong>Peso:</strong> {measurements[0].weight} kg
          </p>
        )}
      </div>
    </div>
  );
};

export default BirdProfilePreview;
