import React from "react";
import { Accordion, Segment } from "semantic-ui-react";

const RodentCareDetails = () => {
  const panels = [
    {
      key: "veterinary-exam",
      title: "Exame Veterinário Inicial",
      content: {
        content: (
          <div>
            <p>
              Ao adquirir um novo roedor, é recomendável realizar um exame
              inicial com um veterinário experiente em pequenos mamíferos. Este
              exame pode incluir uma avaliação geral de saúde e orientações
              específicas sobre cuidados necessários.
            </p>
          </div>
        ),
      },
    },
    {
      key: "regular-checkups",
      title: "Check-ups Regulares",
      content: {
        content: (
          <div>
            <p>
              Anualmente: Realizar visitas anuais ao veterinário é essencial
              para monitorizar a saúde do seu roedor e identificar precocemente
              possíveis problemas de saúde.
            </p>
          </div>
        ),
      },
    },
    {
      key: "nutrition",
      title: "Nutrição Adequada",
      content: {
        content: (
          <div>
            <p>
              Dieta específica para a espécie: Alimente o seu roedor com uma
              dieta formulada para a sua espécie, que pode incluir pelotas
              comerciais, feno fresco (especialmente para porquinhos-da-índia e
              chinchilas), vegetais frescos e uma quantidade limitada de frutas.
            </p>
            <p>Água fresca: Garanta o acesso contínuo a água fresca e limpa.</p>
          </div>
        ),
      },
    },
    {
      key: "environment-maintenance",
      title: "Manutenção do Ambiente",
      content: {
        content: (
          <div>
            <p>
              Limpeza regular: Limpe a gaiola regularmente para evitar o
              acumular de fezes e urina, que podem causar problemas de saúde.
            </p>
            <p>
              Ambiente enriquecido: Proporcione uma gaiola espaçosa com
              materiais para ninho, brinquedos e oportunidades de escavação ou
              escalada, conforme adequado à espécie.
            </p>
          </div>
        ),
      },
    },
    {
      key: "parasite-prevention",
      title: "Prevenção de Parasitas",
      content: {
        content: (
          <div>
            <p>
              Consultas regulares ao veterinário: Discuta com o seu veterinário
              a necessidade de tratamentos preventivos contra parasitas internos
              e externos.
            </p>
          </div>
        ),
      },
    },
    {
      key: "specific-care-guinea-pigs",
      title: "Cuidados Específicos para Porquinhos-da-Índia",
      content: {
        content: (
          <div>
            <p>
              Suplementação de Vitamina C: Porquinhos-da-Índia não produzem
              vitamina C, pelo que precisam de suplementação diária através de
              uma dieta rica em vitamina C ou de suplementos específicos.
            </p>
          </div>
        ),
      },
    },
    {
      key: "handling",
      title: "Socialização e Manipulação",
      content: {
        content: (
          <div>
            <p>
              Manipulação cuidadosa: Roedores podem ser tímidos e necessitam de
              manipulação regular e delicada para se habituarem ao contacto
              humano. Este processo é essencial para desenvolver uma ligação com
              o seu pet.
            </p>
          </div>
        ),
      },
    },
  ];

  return (
    <Segment basic>
      <Accordion panels={panels} styled fluid />
    </Segment>
  );
};

export default RodentCareDetails;
