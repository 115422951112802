import React from "react";
import { Accordion, Segment } from "semantic-ui-react";

const DogCareDetails = () => {
  const panels = [
    {
      key: "veterinary-exam",
      title: "Exame Veterinário Inicial",
      content: {
        content: (
          <div>
            <p>
              Ao adquirir um novo cão, leve-o ao veterinário para um check-up
              inicial. Este exame pode incluir avaliação física, testes para
              doenças infeciosas e recomendações sobre cuidados essenciais.
            </p>
          </div>
        ),
      },
    },
    {
      key: "vaccinations-puppies",
      title: "Vacinações Iniciais (Cachorros)",
      content: {
        content: (
          <div>
            <p>
              8 semanas: Primeira dose da vacina contra Tosse do Canil (se
              necessário).
            </p>
            <p>6 a 8 semanas: Primeira dose da vacina DHLPP.</p>
            <p>10 a 12 semanas: Segunda dose da DHLPP.</p>
            <p>12 a 16 semanas: Vacina antirrábica.</p>
            <p>14 a 16 semanas: Terceira dose da DHLPP.</p>
            <p>6 meses: Primeira dose da vacina contra Leishmaniose.</p>
            <p>1 ano: Primeira dose da vacina contra Dirofilariose.</p>
          </div>
        ),
      },
    },
    {
      key: "vaccinations-adults",
      title: "Vacinações Anuais (Cães Adultos)",
      content: {
        content: (
          <div>
            <p>Anualmente: Reforço da vacina DHLPP.</p>
            <p>
              Anualmente: Reforço da vacina antirrábica (conforme indicação
              veterinária, pode ser a cada três anos).
            </p>
            <p>Anualmente: Reforço da vacina contra Leishmaniose.</p>
            <p>Anualmente: Reforço da vacina contra Dirofilariose.</p>
            <p>
              Anualmente: Reforço da vacina contra Tosse do Canil (dependendo do
              risco de exposição).
            </p>
          </div>
        ),
      },
    },
    {
      key: "parasite-prevention",
      title: "Prevenção de Parasitas",
      content: {
        content: (
          <div>
            <p>
              Parasitas Internos: Mensalmente até aos 6 meses, depois
              trimestralmente.
            </p>
            <p>
              Parasitas Externos: Coleiras de proteção com duração de até 1 ano.
            </p>
            <p>
              Parasitas Externos: Nos meses de verão, pode reforçar com
              comprimidos ou ampolas, conforme necessidade.
            </p>
          </div>
        ),
      },
    },
    {
      key: "chip-and-sterilization",
      title: "Colocação de Chip e Esterilização",
      content: {
        content: (
          <div>
            <p>
              Colocação de Chip: Geralmente realizada na primeira consulta
              veterinária ou após a terceira dose da vacina DHLPP.
            </p>
            <p>
              Esterilização (Castrar ou Ovariectomia): Entre os 6 e os 9 meses,
              dependendo do porte da raça e das recomendações do veterinário.
            </p>
          </div>
        ),
      },
    },
  ];

  return (
    <Segment basic>
      <Accordion panels={panels} styled fluid />
    </Segment>
  );
};

export default DogCareDetails;
