import React from "react";
import {
  Container,
  Segment,
  Header,
  Divider,
  Step,
  Icon,
} from "semantic-ui-react";

const DifferentiatorsSteps = () => {
  return (
    <Segment inverted style={{ padding: "2em 0em" }} vertical>
      <Header as="h3" style={{ fontSize: "2em", textAlign: "center" }}>
        O Que Nos Distingue
      </Header>
      <Divider style={{ maxWidth: "80%", margin: "auto" }} />
      <br />
      <Container>
        <Step.Group fluid vertical>
          <Step style={{ backgroundColor: "transparent" }}>
            <Icon name="check circle" color="green" />
            <Step.Content>
              <Step.Title className="step-title">
                Simplicidade e Acesso
              </Step.Title>
              <Step.Description className="step-content">
                Facilite a gestão do seu animal de estimação com acesso rápido a
                informações essenciais, como vacinas, desparasitação e histórico
                médico.
              </Step.Description>
            </Step.Content>
          </Step>

          <Step style={{ backgroundColor: "transparent" }}>
            <Icon name="check circle" color="green" />
            <Step.Content>
              <Step.Title className="step-title">
                Abordagem Centrada no Utilizador
              </Step.Title>
              <Step.Description className="step-content">
                Desenvolvida a partir de experiências reais, a nossa plataforma
                é intuitiva e prática, adaptando-se às necessidades dos donos de
                animais.
              </Step.Description>
            </Step.Content>
          </Step>

          <Step style={{ backgroundColor: "transparent" }}>
            <Icon name="check circle" color="green" />
            <Step.Content>
              <Step.Title className="step-title">
                Um Recurso Indispensável
              </Step.Title>
              <Step.Description className="step-content">
                Mais do que uma aplicação, o{" "}
                <strong className="step-title">PetSeguro</strong> é o seu aliado
                essencial para cuidar da saúde e bem-estar do seu animal de
                estimação.
              </Step.Description>
            </Step.Content>
          </Step>
        </Step.Group>
      </Container>
    </Segment>
  );
};

export default DifferentiatorsSteps;
