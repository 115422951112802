import React from "react";
import { Segment, Header, Image, Grid, Divider } from "semantic-ui-react";
import photo from "../../../../resources/other/eureka.png";

const CompanyHistory = () => (
  <Segment inverted style={{ padding: "2em 0em" }} vertical textAlign="center">
    <Header
      className="step-title"
      as="h3"
      style={{ fontSize: "2em", textAlign: "center" }}
    >
      A Nossa História
    </Header>
    <Divider style={{ maxWidth: "80%", margin: "auto" }} />
    <br />
    <Grid container stackable verticalAlign="middle">
      <Grid.Row>
        <Grid.Column width={8}>
          <p style={{ fontSize: "1.33em" }}>
            Tudo começou com a gestão da saúde dos meus dois cães – um desafio
            que revelou a falta de uma solução centralizada e acessível.
            Inspirado por esta necessidade, nasceu o{" "}
            <strong className="step-title">PetSeguro</strong>, uma plataforma
            desenvolvida para facilitar o cuidado dos nossos fiéis companheiros
            de quatro patas.
          </p>
        </Grid.Column>
        <Grid.Column width={8}>
          <Image
            circular
            centered
            size="large"
            src={photo}
            alt="História do PetSeguro"
            style={{
              border: "5px solid #51cb61",
              padding: "4px",
              backgroundColor: "#fff",
            }}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={16}>
          <p style={{ fontSize: "1.33em", textAlign: "center" }}>
            Transformar esta visão em realidade não foi uma tarefa fácil. Cada
            obstáculo enfrentado durante o desenvolvimento do{" "}
            <strong className="step-title">PetSeguro</strong> reforçou a nossa
            determinação em criar uma ferramenta indispensável para os donos de
            animais de estimação. Estamos comprometidos em melhorar
            continuamente, garantindo que o{" "}
            <strong className="step-title">PetSeguro</strong> vá além das
            expectativas da nossa crescente comunidade.
          </p>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </Segment>
);

export default CompanyHistory;
