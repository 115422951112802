import React, { useState } from "react";
import {
  Button,
  Form,
  Input,
  Container,
  Select,
  Dropdown,
  Checkbox,
} from "semantic-ui-react";
import {
  LogErrors,
  getCurrentDateTime,
  getDogBreeds,
  notifyError,
  notifySuccess,
  removeId,
} from "../../resources/lib";
import {
  addDataToCollection,
  updateDataByDocId,
} from "../../resources/firebaseLib";
import { dogsCollection } from "../../resources/const/firebaseCollections";
import { useAuth } from "../account/AuthContext";
import { dogFurTypes, sexOptions } from "../../resources/const/dropdownOptions";

const DogForm = ({ pet, reload, closeModal }) => {
  const { user } = useAuth();
  const [petProfile, setPetProfile] = useState(
    pet
      ? pet
      : {
          type: "dog",
          name: "",
          birthDate: "",
          breed: "",
          isMixedBreed: false,
          sex: "",
          coatColor: "",
          coatType: "",
        }
  );
  const isUpdate = pet ? true : false;

  const breedOptions = getDogBreeds();

  const handleChange = (e, { name, value }) => {
    setPetProfile({ ...petProfile, [name]: value });
  };

  const handleDropChange = (e, { name, value }) =>
    setPetProfile({ ...petProfile, [name]: value });

  const handleCheckChange = () => {
    setPetProfile({ ...petProfile, isMixedBreed: !petProfile.isMixedBreed });
  };

  async function populateCollection(data, uid) {
    try {
      const addData = {
        uid: uid,
        type: data.type,
        name: data.name.trim(),
        birthDate: data.birthDate,
        breed: data.breed,
        isMixedBreed: data.isMixedBreed,
        sex: data.sex,
        coatColor: data.coatColor,
        coatType: data.coatType,
        creationDate: getCurrentDateTime(),
        isDelete: 0,
      };

      return await addDataToCollection(dogsCollection, addData);
    } catch (error) {
      LogErrors(error);
      return false;
    }
  }

  const validObject = () => {
    return (
      petProfile.name.trim() !== "" &&
      petProfile.birthDate !== "" &&
      petProfile.breed !== "" &&
      petProfile.sex !== ""
    );
  };

  const handleSubmit = async () => {
    if (!isUpdate) {
      const result = await populateCollection(petProfile, user.uid);
      if (result) {
        notifySuccess("Animal de Estimação adicionado com sucesso.");
        reload(user);
        closeModal();
      } else {
        notifyError("Falha ao criar o seu pet.");
        closeModal();
      }
    } else {
      const result = await updateDataByDocId(
        dogsCollection,
        petProfile.id,
        removeId(petProfile)
      );
      if (result) {
        notifySuccess("Animal de Estimação atualizado com sucesso.");
        reload(user);
        closeModal();
      } else {
        notifyError("Falha ao atualizar o seu pet.");
        closeModal();
      }
    }
  };

  return (
    <Container>
      <Form
        onSubmit={handleSubmit}
        style={{
          padding: "20px",
          maxWidth: "600px",
          margin: "auto",
        }}
      >
        {/* Nome e Data de Nascimento */}
        <Form.Group widths="equal" style={{ marginBottom: "20px" }}>
          <Form.Field required>
            <label style={{ fontWeight: "bold", fontSize: "1.1em" }}>
              Nome
            </label>
            <Input
              placeholder="Nome do Animal de Estimação"
              name="name"
              value={petProfile.name}
              onChange={handleChange}
              style={{ borderRadius: "10px", padding: "10px" }}
            />
          </Form.Field>
          <Form.Field required>
            <label style={{ fontWeight: "bold", fontSize: "1.1em" }}>
              Data de Nascimento
            </label>
            <Input
              placeholder="Data de Nascimento ou Idade"
              name="birthDate"
              type="date"
              value={petProfile.birthDate}
              onChange={handleChange}
              style={{ borderRadius: "10px", padding: "10px" }}
            />
          </Form.Field>
        </Form.Group>

        {/* Raça e Mistura */}
        <Form.Group widths="equal" style={{ marginBottom: "20px" }}>
          <Form.Field required>
            <label style={{ fontWeight: "bold", fontSize: "1.1em" }}>
              Raça
            </label>
            <Dropdown
              placeholder="Raça"
              name="breed"
              fluid
              search
              selection
              options={breedOptions}
              value={petProfile.breed}
              onChange={handleDropChange}
              style={{ borderRadius: "10px" }}
            />
          </Form.Field>
          <Form.Field>
            <label style={{ fontWeight: "bold", fontSize: "1.1em" }}>
              Mistura de Raça?
            </label>
            <Checkbox
              label="Sim"
              checked={petProfile.isMixedBreed}
              onChange={handleCheckChange}
              style={{ marginTop: "10px" }}
            />
          </Form.Field>
        </Form.Group>

        {/* Sexo */}
        <Form.Field style={{ marginBottom: "20px" }} required>
          <label style={{ fontWeight: "bold", fontSize: "1.1em" }}>Sexo</label>
          <Select
            placeholder="Selecione o sexo"
            name="sex"
            options={sexOptions}
            value={petProfile.sex}
            onChange={handleChange}
            style={{ borderRadius: "10px" }}
          />
        </Form.Field>

        {/* Pêlo */}
        <Form.Group widths="equal" style={{ marginBottom: "20px" }}>
          <Form.Field>
            <label style={{ fontWeight: "bold", fontSize: "1.1em" }}>
              Côr do Pêlo
            </label>
            <Input
              placeholder="Côr"
              name="coatColor"
              value={petProfile.coatColor}
              onChange={handleChange}
              style={{ borderRadius: "10px" }}
            />
          </Form.Field>
          <Form.Field>
            <label style={{ fontWeight: "bold", fontSize: "1.1em" }}>
              Tipo de Pêlo
            </label>
            <Dropdown
              placeholder="Tipo"
              name="coatType"
              fluid
              search
              selection
              options={dogFurTypes}
              value={petProfile.coatType}
              onChange={handleDropChange}
              style={{ borderRadius: "10px" }}
            />
          </Form.Field>
        </Form.Group>

        {/* Botão Gravar */}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <Button
            type="submit"
            className="primary-color"
            style={{
              padding: "10px 30px",
              borderRadius: "30px",
              fontSize: "1.2em",
              width: "50%",
            }}
            disabled={!validObject()}
          >
            Gravar Perfil
          </Button>
        </div>
      </Form>
    </Container>
  );
};

export default DogForm;
