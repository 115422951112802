import { Segment, Container, Header, Button } from "semantic-ui-react";
import { Link } from "react-router-dom";
import Grow from "../../../global/Grow";
import logo from "../../../../resources/new_logo_nbg.png"; // Altere o caminho conforme necessário

const HomeBanner = () => {
  const isMobile = window.innerWidth < 768;

  return (
    <Segment inverted textAlign="center" vertical>
      <Container
        style={{
          backgroundImage: `url(${logo})`,
          backgroundSize: "cover",
          backgroundPosition: "center center",
          textAlign: "center",
          padding: isMobile ? "10px 2px" : "40px 20px",
        }}
      >
        <Header
          as="h1"
          inverted
          style={{
            fontSize: isMobile ? "2.5em" : "4em",
            fontWeight: "normal",
            marginBottom: "1em",
            marginTop: isMobile ? "1em" : "3em",
          }}
        >
          Bem-vindo ao <strong className="step-title">PetSeguro</strong>
        </Header>
        <Header
          as="h2"
          content="Ligue-se ao seu animal de estimação de forma inovadora. Simplifique os cuidados diários e garanta a saúde e o bem-estar do seu melhor amigo com a nossa plataforma completa e fácil de usar."
          inverted
          style={{
            fontSize: isMobile ? "1.4em" : "1.7em",
            fontWeight: "normal",
          }}
        />
        <Grow>
          <Button size="huge" className="primary-color" as={Link} to="/about">
            Saiba Mais
          </Button>
        </Grow>
      </Container>
    </Segment>
  );
};

export default HomeBanner;
