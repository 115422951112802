import React from "react";
import { Header, Container, Segment } from "semantic-ui-react";
import logo from "../../../../resources/new_logo_nbg.png";

const FAQHeader = () => {
  const isMobile = window.innerWidth < 758;

  return (
    <Segment
      inverted
      textAlign="center"
      style={{ minHeight: "30vh", padding: "1em 0em" }}
      vertical
    >
      <Container
        style={{
          backgroundImage: `url(${logo})`,
          backgroundSize: "cover",
          backgroundPosition: "center center",
          textAlign: "center",
          padding: isMobile ? "10px 2px" : "100px 20px",
        }}
      >
        <Header
          as="h1"
          inverted
          style={{
            fontSize: "4em",
            fontWeight: "normal",
            marginBottom: 0,
            marginTop: "3em",
          }}
        >
          Perguntas Frequentes
        </Header>
        <Header
          as="h2"
          inverted
          style={{ fontSize: "1.7em", fontWeight: "normal" }}
        >
          Encontre respostas para as perguntas mais frequentes sobre o{" "}
          <strong className="step-title">PetSeguro</strong>. . Se não encontrar
          o que precisa, estamos prontos para ajudar.
        </Header>
      </Container>
    </Segment>
  );
};

export default FAQHeader;
