import React, { useState } from "react";
import {
  Button,
  Form,
  Input,
  Container,
  Select,
  Dropdown,
} from "semantic-ui-react";
import {
  LogErrors,
  getCurrentDateTime,
  getReptileBreeds,
  notifyError,
  notifySuccess,
  removeId,
} from "../../resources/lib";
import {
  addDataToCollection,
  updateDataByDocId,
} from "../../resources/firebaseLib";
import { reptilesCollection } from "../../resources/const/firebaseCollections";
import { useAuth } from "../account/AuthContext";
import {
  reptileSkinPatterns,
  sexOptions,
} from "../../resources/const/dropdownOptions";

const ReptileForm = ({ pet, reload, closeModal }) => {
  const { user } = useAuth();
  const [petProfile, setPetProfile] = useState(
    pet
      ? pet
      : {
          type: "reptile",
          name: "",
          birthDate: "",
          species: "",
          sex: "",
          skinColor: "",
          skinPattern: "",
        }
  );
  const isUpdate = pet ? true : false;

  const breedOptions = getReptileBreeds();

  const handleChange = (e, { name, value }) => {
    setPetProfile({ ...petProfile, [name]: value });
  };

  const handleDropChange = (e, { name, value }) =>
    setPetProfile({ ...petProfile, [name]: value });

  async function populateCollection(data, uid) {
    try {
      const addData = {
        uid: uid,
        type: data.type,
        name: data.name.trim(),
        birthDate: data.birthDate,
        species: data.species,
        sex: data.sex,
        skinColor: data.skinColor,
        skinPattern: data.skinPattern,
        creationDate: getCurrentDateTime(),
        isDelete: 0,
      };

      return await addDataToCollection(reptilesCollection, addData);
    } catch (error) {
      LogErrors(error);
      return false;
    }
  }

  const validObject = () => {
    return (
      petProfile.name.trim() !== "" &&
      petProfile.birthDate !== "" &&
      petProfile.breed !== "" &&
      petProfile.sex !== ""
    );
  };

  const handleSubmit = async () => {
    if (!isUpdate) {
      const result = await populateCollection(petProfile, user.uid);
      if (result) {
        notifySuccess("Animal de Estimação adicionado com sucesso.");
        reload(user);
        closeModal();
      } else {
        notifyError("Falha ao criar o seu pet.");
        closeModal();
      }
    } else {
      const result = await updateDataByDocId(
        reptilesCollection,
        petProfile.id,
        removeId(petProfile)
      );
      if (result) {
        notifySuccess("Animal de Estimação atualizado com sucesso.");
        reload(user);
        closeModal();
      } else {
        notifyError("Falha ao atualizar o seu pet.");
        closeModal();
      }
    }
  };

  return (
    <Container>
      <Form
        onSubmit={handleSubmit}
        style={{
          padding: "20px",
          maxWidth: "500px",
          margin: "auto",
        }}
      >
        {/* Nome */}
        <Form.Field style={{ marginBottom: "20px" }}>
          <label style={{ fontWeight: "bold", fontSize: "1.1em" }}>Nome</label>
          <Input
            placeholder="Nome do Animal de Estimação"
            name="name"
            value={petProfile.name}
            onChange={handleChange}
            style={{ borderRadius: "10px" }}
          />
        </Form.Field>

        {/* Data de Nascimento */}
        <Form.Field style={{ marginBottom: "20px" }}>
          <label style={{ fontWeight: "bold", fontSize: "1.1em" }}>
            Data de Nascimento
          </label>
          <Input
            placeholder="Data de Nascimento"
            name="birthDate"
            type="date"
            value={petProfile.birthDate}
            onChange={handleChange}
            style={{ borderRadius: "10px" }}
          />
        </Form.Field>

        {/* Espécie */}
        <Form.Field style={{ marginBottom: "20px" }}>
          <label style={{ fontWeight: "bold", fontSize: "1.1em" }}>
            Espécie
          </label>
          <Dropdown
            placeholder="Espécie do Réptil"
            name="species"
            fluid
            search
            selection
            options={breedOptions}
            value={petProfile.species}
            onChange={handleDropChange}
            style={{ borderRadius: "10px" }}
          />
        </Form.Field>

        {/* Sexo */}
        <Form.Field style={{ marginBottom: "20px" }}>
          <label style={{ fontWeight: "bold", fontSize: "1.1em" }}>Sexo</label>
          <Select
            placeholder="Selecione o sexo"
            name="sex"
            options={sexOptions}
            value={petProfile.sex}
            onChange={handleChange}
            style={{ borderRadius: "10px" }}
          />
        </Form.Field>

        {/* Pele */}
        <Form.Field
          style={{ marginBottom: "20px", display: "flex", gap: "10px" }}
        >
          <div style={{ flex: 1 }}>
            <label style={{ fontWeight: "bold", fontSize: "1.1em" }}>Cor</label>
            <Input
              placeholder="Cor da Pele"
              name="skinColor"
              value={petProfile.skinColor}
              onChange={handleChange}
              style={{ borderRadius: "10px" }}
            />
          </div>
          <div style={{ flex: 1 }}>
            <label style={{ fontWeight: "bold", fontSize: "1.1em" }}>
              Padrão
            </label>
            <Dropdown
              placeholder="Padrão da Pele"
              name="skinPattern"
              fluid
              search
              selection
              options={reptileSkinPatterns}
              value={petProfile.skinPattern}
              onChange={handleDropChange}
              style={{ borderRadius: "10px" }}
            />
          </div>
        </Form.Field>

        {/* Botão */}
        <div
          style={{
            display: "flex",
            justifyContent: "center", // Centraliza o botão
            marginTop: "20px",
          }}
        >
          <Button
            type="submit"
            className="primary-color"
            style={{
              padding: "10px 30px",
              borderRadius: "30px",
              fontSize: "1.2em",
              width: "70%",
            }}
            disabled={!validObject()}
          >
            Gravar Perfil
          </Button>
        </div>
      </Form>
    </Container>
  );
};

export default ReptileForm;
